<template>
    <div class="relative">
        <slot name="activator" :on="{'click': toggle}"></slot>
        <div :class="{'hidden': visible === false}" class="absolute top-0 mt-full bg-white py-3 rounded-sm shadow-hard w-48 z-20 right-0">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            visible: false
        };
    },
    methods: {
        toggle() {
            this.visible = !this.visible;
        }
    }
};
</script>
