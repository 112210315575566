<template>
    <div>
        <v-table :headers="[
            {text: 'ObjektId', value: 'objekt_id', align: 'left'},
            {text: 'Titel', value: 'title', align: 'left'},
            {text: 'Addresse', value: 'address', align: 'left'},
            {text: 'PLZ', value: 'zip', align: 'left'},
            {text: 'Ort', value: 'location', align: 'left'},
            {text: 'TimoTimer', value: 'timer', align: 'left', sortable: false},
            ]"
            :items="data"
            actions
        >
            <template slot="ObjektId" slot-scope="{ item }">
                {{item.objekt_id}}
            </template>

            <template slot="Titel" slot-scope="{ item }">
                {{item.title}}
            </template>

            <template slot="Addresse" slot-scope="{ item }">
                {{item.address}}
            </template>

            <template slot="PLZ" slot-scope="{ item }">
                {{item.zip}}
            </template>

            <template slot="Ort" slot-scope="{ item }">
                {{item.location}}
            </template>

            <template slot="TimoTimer" slot-scope="{ item }">
                <div class="btn-icongroup">
                    <button type="button" @click="sw('timer_active', item)" :class="btnBg('timer_active', item)">
                        <span class="fa fa-check"></span>
                    </button>
                    <button type="button" @click="sw('calendar_active', item)" :class="btnBg('calendar_active', item)">
                        <span class="fa fa-calendar"></span>
                    </button>
                    <button type="button" @click="sw('show_address', item)" :class="btnBg('show_address', item)">
                        <span class="fa fa-address-card"></span>
                    </button>
                </div>
            </template>

            <template slot="actions" slot-scope="{ item }">
                <goto :href="item.timer.links.edit" icon="pencil" class="btn btn-primary ml-1 text-white"></goto>
                <goto :href="item.timer.links.events.index" icon="calendar" class="btn btn-primary ml-1 text-white"></goto>
            </template>
        </v-table>
    </div>
</template>

<script>
import App from '../../layouts/App';

export default {
    
    layout: App,

    props: {
        data: {}
    },

    methods: {
        sw(property, item) {
            var payload = item.timer;
            payload[property] = !payload[property];
            this.$inertia.patch(item.timer.links.update, payload);
        },
        btnBg(property, item) {
            return item.timer[property] ? 'bg-green-600' : 'bg-green-600 opacity-25';
        }
    }
};
</script>
