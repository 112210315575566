<template>
    <div class="relative p-6">
        <toolbar>
            <goto :href="data.meta.links.create" icon="plus" class="text-white">Objekt anlegen</goto>
        </toolbar>

        <v-table :headers="[
            {text: 'ObjektId', value: 'objekt_id', align: 'left'},
            ]"
            :items="data.data"
            :sort="sortValue"
            @sort="sortValue = $event"
            actions
        >
            <template slot="ObjektId" slot-scope="{ item }">
                {{item.objekt_id}}
            </template>

            <template slot="actions" slot-scope="{ item }">
                <goto :href="item.links.timer" icon="home" class="btn btn-primary ml-1 text-white"></goto>
                <goto :href="item.links.edit" icon="pencil" class="btn btn-primary ml-1 text-white"></goto>
                <goto  @click="destroy(item)" icon="trash" class="btn btn-danger ml-1 text-white"></goto>
            </template>
        </v-table>

        <pagination @goto="pageValue = $event" :value="data.meta"></pagination>
    </div>
</template>

<script>
import App from '../../layouts/App';
import Pagination from '../../components/Pagination.vue';

export default {
    layout: App,

    props: { page: {}, data: {}, sort: {}, direction: {} },

    components: { Pagination },
    
    computed: {
        sortValue: {
            get() {
                return {
                    key: this.sort,
                    direction: this.direction
                };
            },
            set(v) {
                this.$inertia.visit(window.location.origin + window.location.pathname, {
                    data: { page: this.page, sort: v.key, direction: v.direction },
                    preserveScroll: true,
                    preserveState: true
                });
            }
        },
        pageValue: {
            get() {
                return this.page;
            },
            set(v) {
                this.$inertia.visit(window.location.origin + window.location.pathname, {
                    data: { page: v, sort: this.sort, direction: this.direction },
                    preserveScroll: true,
                    preserveState: true
                });
            }
        },
    }
};
</script>
