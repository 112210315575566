<template>
    <div v-if="typeof value !== 'undefined'">
        <grid rows="1fr 1fr" v-if="typeof m !== 'undefined'" dense>
            <item col="1"><div class="subtitle">Eigentümer</div></item>
            <item col="1"><f-text v-model="m.firstname" label="Vorname"></f-text></item>
            <item col="1"><f-text v-model="m.lastname" label="Nachname"></f-text></item>
            <item col="1"><f-text v-model="m.address" label="Strasse"></f-text></item>
            <item col="1"><f-text v-model="m.nr" label="Nr"></f-text></item>
            <item col="1"><f-text v-model="m.zip" label="PLZ"></f-text></item>
            <item col="1"><f-text v-model="m.location" label="Ort"></f-text></item>
            <div col="2" class="subtitle">Kontaktdaten</div>
            <item col="2"><f-text v-model="m.phone" label="Tel (Festnetz)"></f-text></item>
            <item col="2"><f-text v-model="m.mobile" label="Tel (Mobil)"></f-text></item>
            <item col="2"><f-text v-model="m.email" label="E-Mail"></f-text></item>
            <item col="2"><f-text v-model="m.fax" label="Fax"></f-text></item>
        </grid>

        <btn @click="triggerAdd" v-if="typeof m === 'undefined'" primary>Hinzufügen</btn>
        <btn @click="e = -1" v-if="typeof m !== 'undefined'" primary>Speichern</btn>

        <v-table :headers="[
            {text: 'Vorname', value: 'firstname', align: 'left'},
            {text: 'Nachname', value: 'lastname', align: 'left'}
            ]"
            :items="value"
            actions
        >
            <template slot="Vorname" slot-scope="{ item }">
                {{item.firstname}}
            </template>

            <template slot="Nachname" slot-scope="{ item }">
                {{item.lastname}}
            </template>

            <template slot="actions" slot-scope="{ item, index }">
                <goto @click="e = index" icon="pencil" class="btn btn-primary ml-1 text-white"></goto>
                <goto @click="triggerDelete(index)" icon="trash" class="btn btn-danger ml-1 text-white"></goto>
            </template>
        </v-table>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            e: -1
        };
    },
    computed: {
        m() {
            return typeof this.value[this.e] === 'undefined' ? undefined : this.value[this.e];
        }
    },
    props: {
        value: {}
    },
    methods: {
        triggerDelete(index) {
            this.value.splice(index, 1);
        },
        triggerAdd() {
            this.e = this.value.push({}) - 1;
        }
    },
    created() {
        if (typeof this.value === 'undefined') {
            this.$emit('input', []);
        }
    }
};
</script>
