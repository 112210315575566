import { kebabCase } from 'lodash';
import merge from 'merge';
const VueInputMask = require('vue-inputmask').default;

var init = function(Vue) {
    Vue.use(VueInputMask);
    Vue.config.productionTip = false;
};

var modules = function(Vue, modules) {
    const requireComponent = require.context(
        './modules', false, /^\.\/[^_].*\.vue$/
    );

    const requireRenderComponent = require.context(
        './modules', false, /^\.\/[^_].*\.component\.js$/
    );

    const requireMixin = require.context(
        './modules', false, /^\.\/[^_].*\.mixin\.js$/
    );

    requireComponent.keys().forEach((file) => {
        let componentName = file.substr(2).replace('.vue', '');

        Vue.component(kebabCase(componentName), requireComponent(file).default);
    });

    requireMixin.keys().forEach((file) => {
        Vue.mixin(requireMixin(file).default);
    });

    requireRenderComponent.keys().forEach((file) => {
        let componentName = file.substr(2).replace('.component.js', '');

        Vue.component(kebabCase(componentName), requireRenderComponent(file).default);
    });
};

export { init, modules };
