export default function(before) {
    return {
        data: function() {
            return {
                colors: ['danger', 'secondary', 'primary', 'default'],
                texts: {
                    danger: 'white',
                    secondary: 'white',
                    primary: 'white',
                    default: 'grey-dark'
                }
            };
        },
        props: {
            secondary: {
                type: Boolean,
                default: false
            },
            danger: {
                type: Boolean,
                default: false
            },
            primary: {
                type: Boolean,
                default: false
            },
            default: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            classes() {
                var desc = this.colors.find((color) => {
                    return this.$props[color] === true;
                });

                var cls = before + '-' + desc;

                if (before === 'bg' && this.texts[desc]) {
                    cls += ' text-' + this.texts[desc];
                }

                return cls;
            }
        }
    };
}
