<template>
    <div>
        <f-checkbox v-model="value.eatexteinfuegen" label="Text bei nicht vorhandenem Ausweis einfügen"></f-checkbox>
        <f-text v-model="value.eainfo" label="Textfeld frei wählbar und speicherbar" multi-line></f-text>
        <f-select v-model="value.eaerst"
            :options="eaerst"
            label="Erstellungsdatum"
        ></f-select>
        <f-select v-model="value.eatype"
            :options="eatype"
            label="Energieausweistyp"
        ></f-select>
        <f-text v-model="value.eagueltig" label="Gültigkeit bis"></f-text>
        <f-select v-model="value.eaee"
            :options="eaee"
            label="Energieeffizienzklasse"
            v-if="value.eaerst == 'EnEv 2014 (ab 2014)'"
        ></f-select>
        <f-select v-model="value.eaprime"
            :options="prime"
            label="Primärenergieträger"
        ></f-select>
        <f-text v-model="value.eaanlage" label="Baujahr Anlage"></f-text>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            eatext: 'Information zum Energieausweis gemäß §16a Abs. 1 EnEV: Der Energieausweis wird gegenwärtig erstellt, liegt aber noch nicht vor. Wir legen diesen spätestens bei der Besichtigung des Objekts vor.',
            eaerst: [
                'EnEv 2009 (bis 2014)', 'EnEv 2014 (ab 2014)', 'liegt zur Besichtigung vor', 'nicht erforderlich'
            ],
            eatype: [
                'Energiebedarf', 'Energieverbrauch'
            ],
            eaee: [
                'A+', 'A', 'B', 'C', 'D', 'F', 'G', 'H'
            ],
            prime: [
                'Öl', 'Gas', 'Elektro', 'Erdwärme', 'Holz', 'Kohle', 'Luft-Wasser-Wärmepumpe', 'Blockheitskraft', 'Fernwärme', 'Solar', 'Pellet', 'Flüssiggas'
            ]
        };
    },
    props: {
        value: {}
    },
    created() {
        this.value.eainfo = this.value.eainfo ? this.value.eainfo : this.eatext;
    }
};
</script>
