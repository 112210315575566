<template>
    <div>
        <table class="w-full">
            <thead>
                <tr>
                    <th v-for="(header, index) in headers"
                        class="py-4 px-6 uppercase border-b border-solid border-grey-lighter text-grey-darker text-sm font-normal text-left"
                        :key="index"
                        @click="sortBy(header)"
                    >
                        <div class="flex h-full items-center">
                            <span class="flex-grow cursor-pointer">
                                {{ header.text }}
                            </span>

                        <svg class="transform" :class="{'rotate-180': sort.direction == 'desc', 'invisible': sort.key != header.value}" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"/></svg>
                        </div>
                    </th>

                    <th class="py-4 px-6 uppercase text-grey-darker text-sm font-normal border-b border-solid border-grey-lighter text-right" v-if="actions">
                        Aktion
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(item, index) in items" :key="index" class="hover:bg-grey-lightest">
                    <td class="py-4 px-6 border-b border-solid border-grey-lighter leading-loose text-grey-darker text-sm"
                        v-for="(header, index) in headers" :key="index"
                    >
                        <slot :name="header.text" :item="item" :index="index"></slot>
                    </td>

                    <td class="py-4 px-6 text-grey-darker text-xs font-normal border-b border-solid border-grey-lighter text-right" v-if="actions">
                        <div class="flex">
                            <slot name="actions" :item="item" :index="index"></slot>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { orderBy } from 'lodash';

export default {
    props: {
        sort: {
            default: function() {
                return {
                    key: '',
                    direction: 'asc'
                };
            }
        },
        headers: {
            default: []
        },
        items: {
            default: function() { return []; }
        },
        actions: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        sortBy(header) {
            if (header.sortable === false) {
                return;
            }

            if (this.sort.key === header.value) {
                this.$emit('sort', {
                    ...this.sort,
                    direction: this.sort.direction == 'asc' ? 'desc' : 'asc'
                });
                return;
            }
            
            this.$emit('sort', {
                key: header.value,
                direction: 'asc'
            });
        }
    }
};
</script>
