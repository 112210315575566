<template>
    <label :for="unique" class="field-wrapper focused">
        <span class="label-font label-placeholder" v-if="label">{{ label }}</span>
        <input type="text" :id="unique" v-model="v"
            class="form-control form-control-outline" :data-cy="dataCy"
            v-mask="'9[9][9][9][9][9][9][9],99'"
        >
    </label>
</template>

<script>
import FormField from './FormField.js';
import accounting from 'accounting';

export default {
    extends: FormField,
    data: function() {
        return {
            focus: false
        };
    },
    props: {
        value: {
            default: undefined
        },
        label: {
            default: false
        }
    },
    computed: {
        v: {
            set(v) {
                if (/[0-9]+,[0-9]{2}/.test(v)) {
                    var val = parseFloat(v.replace(',', '.'));
                    this.$emit('input', parseInt(accounting.unformat(val, 2) * 100));
                }
            },
            get() {
                return accounting.formatMoney(this.value / 100, {
                    precision: 2,
                    format: '%v',
                    thousand: '',
                    decimal: ','
                });
            }
        }
    },
    created() {
        if (typeof this.value === 'undefined') {
            this.$emit('input', '');
        }
    }
};
</script>
