import { template } from 'lodash';

function open(el, params) {
    $(el).addClass('active');

    if ($(el).find('script.tmpl')) {
        var tmpl = template($(el).find('script.tmpl').html());

        $(el).find('script.tmpl').next().html(tmpl(params));
    }
}

function closePopup(el, params) {
    $(el).removeClass('active');

    if ($(el).find('script.tmpl')) {
        $(el).find('script.tmpl').next().html('');
    }
}

function register(el) {
    if (! $(el).data('popupRegistered')) {
        $(el).data('popupRegistered', true);

        $(el).click(function() {
            var target = $(this).data('target') ? $(this).data('target') : $(this).attr('href');
            open($(target));
        });
    }
}

function registerConfirm(el) {
    if (! $(el).data('confirmRegistered')) {
        $(el).data('confirmRegistered', true);

        $(el).click(function() {
            var target = $(this).data('target') ? $(this).data('target') : $(this).attr('href');
            open($(target));
        });
    }
}

export default function($) {
    $.fn.popup = function(event) {
        $(this).each(function() {
            register(this);

            if (event == 'open') {
                openPopup(this);
            }
        });

    };

    $.fn.confirmPopup = function(event, params) {
        registerConfirm(this);

        if (event == 'open') {
            open(this, params);
        }

        var p = this;

        return new Promise(function(resolve, reject) {
            $(p).find('[data-confirm]').click(function() {
                closePopup(p);
                resolve(p);
            });
            $(p).find('[data-reject]').click(function() {
                closePopup(p);
                reject(p);
            });
        });
    };
};


