<template>
    <a href="#" v-if="isButton"
        :class="{'w-8 h-8 p-0 items-center justify-center': !hasText}"
        class="inline-flex no-underline"
        @click="$emit('click', $event)"
    >
        <span v-if="icon" :class="['fa', 'fa-'+icon, {'pr-1 w-5': hasText}, {'': !hasText}]"></span>
        <span class="pl-1" :class="textClass" v-if="hasText"><slot></slot></span>
    </a>
    <inertia-link :href="href" @click="$emit('click', $event)" class="cursor-pointer inline-flex items-center no-underline"
        :class="{'w-8 h-8 p-0 items-center justify-center': !hasText}"
    v-else>
        <span v-if="icon" :class="['fa', 'fa-'+icon, {'pr-1 w-5': hasText}, {'': !hasText}]"></span>
        <span class="pl-1" :class="textClass" v-if="hasText"><slot></slot></span>
    </inertia-link>
</template>

<script>
export default {
    props: {
        size: {
            default: 'md',
            type: String
        },
        route: {},
        href: {},
        icon: {},
        params: {
            default: function() {
                return {};
            }
        }
    },
    methods: {
        goto() {
            if (this.route) {
                this.$router.push({ name: this.route });
            } else if (this.href) {
                location.href = this.href;
            }
        },
        clicked() {
            this.$emit('click');
        }
    },
    computed: {
        hasText() {
            return this.$slots.default !== undefined;
        },
        isButton() {
            return this.href === undefined;
        },
        textClass() {
            return this.size === 'sm' ? 'text-xs' : 'text-sm';
        }
    }
};
</script>
