<template>
    <div class="field-wrapper">
        <label :for="unique" class="fancy-switch" :class="{'fancy-switch-sm': sm === true}">
            <input type="checkbox" v-model="v" :id="unique" :disabled="disabled" />
            <span :class="['tw-bg-primary']"></span>
            <span v-if="label" class="w-full">{{ label }}</span>
            <span v-if="$slots.default" class="w-full aside-info"><slot></slot></span>
        </label>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'items',
        event: 'input'
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            default: false
        },
        label: {
            default: false
        },
        items: {
            default: undefined
        },
        sm: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        v: {
            set(v) {
                if (this.disabled === true) {
                    return;
                }

                if (typeof this.items === 'boolean') {
                    this.$emit('input', v);
                    return;
                }

                var a = this.items.filter(i => i !== this.value);
                if (v) {
                    a.push(this.value);
                }

                this.$emit('input', a);
            },
            get() {
                if (typeof this.items === 'boolean') {
                    return this.items;
                }

                if (typeof this.items === 'undefined') {
                    return this.$emit('input', false);
                }

                return this.items.indexOf(this.value) !== -1;
            }
        },
        unique() {
            return Math.random().toString(36).substr(2, 9) +
                Math.random().toString(36).substr(2, 9)
            ;
        }
    },
    mounted() {
        if (typeof this.items === 'undefined') {
            this.$emit('input', false);
        }
    }
};
</script>
