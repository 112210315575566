export default function() {
    function applyAccessors(item, state, getters, rootState) {
        if (typeof rootState[getters.model] === 'undefined') {
            return item;
        }

        var accessors = rootState[getters.model].accessors;

        if (typeof accessors === 'undefined') {
            return item;
        }

        Object.keys(accessors).forEach((accessor) => {
            item[accessor] = accessors[accessor](item);
        });

        return item;
    }

    return {
        state: {
            items: [],
            page: 1,
            isOnePage: false,
            perPage: 15,
            loaded: false
        },
        mutations: {
            switchOnePageMode(state) {
                state.isOnePage = !state.isOnePage;
            },
            setAll(state, data) {
                state.items = data;
            },
            forgetId(state, id) {
                state.items = state.items.filter(item => item.id !== id);
            },
            push(state, data) {
                state.items.push(data);
            },
            set(state, data) {
                var index = state.items.findIndex(item => item.id === data.id);
                if (index === -1) {
                    state.items.push(data);
                } else {
                    state.items.splice(index, 1, data);
                }
            },
            update(state, data) {
                var index = state.items.findIndex(item => item.id === data.id);
                state.items.splice(index, 1, data);
            },
            setLoaded(state, isLoaded) {
                state.loaded = isLoaded;
            },
            setPage(state, page) {
                state.page = page;
            }
        },

        getters: {
            items(state, getters, rootState) {
                return state.items.map(item => applyAccessors(item, state, getters, rootState));
            },
            show: (state) => (id) => {
                return state.items.find((item) => item.id === id);
            },
            loaded(state) {
                return state.loaded;
            },
            isOnePage(state) {
                return state.isOnePage;
            },
            filteredItems(state, getters, rootState) {
                var items = state.items;

                if (rootState.search.text.length !== 0) {
                    items = items.filter(item => {
                        return getters.search(item, rootState.search.text);
                    });
                }

                return items.map(item => applyAccessors(item, state, getters, rootState));
            },
            pages(state, getters, rootState) {
                return Math.ceil(getters.filteredItems.length / state.perPage);
            },
            page(state) {
                return state.page;
            },
            onLastPage(state) {
                return state.page === state.pages;
            },
            onFirstPage(state) {
                return state.page === 1;
            },
            pageRange(state, getters) {
                var a = [ ...Array(getters.pages + 1).keys() ];
                a.shift();

                return a.slice(Math.max(state.page - 3, 0), Math.max(state.page - 3, 0) + 6);
            },
            currentPageItems(state, getters) {
                var items = getters.filteredItems;

                if (state.isOnePage) {
                    return items;
                }

                return items.slice((getters.page - 1) * state.perPage, (getters.page - 1) * state.perPage + state.perPage);
            }
        },

        actions: {
            show({ getters, commit, dispatch }, id) {
                return new Promise((resolve, reject) => {
                    axios.get(`/${getters.model}/${id}`).then((ret) => {
                        commit('set', ret.data);
                        resolve(ret.data);
                    }).catch((error) => {
                        dispatch('error', { error, reject });
                    });
                });
            },
            destroy({ getters, commit, dispatch }, id) {
                return new Promise((resolve, reject) => {
                    axios.delete(`/${getters.model}/${id}`).then((ret) => {
                        dispatch('messages/success', getters.messages.destroyed, { root: true });
                        commit('forgetId', id);
                        resolve();
                    }).catch((error) => {
                        dispatch('error', { error, reject });
                    });
                });
            },
            store({ getters, commit, dispatch }, values) {
                return new Promise((resolve, reject) => {
                    axios.post(`/${getters.model}`, values).then((ret) => {
                        dispatch('messages/success', getters.messages.stored, { root: true });
                        commit('push', ret.data);
                        resolve(ret);
                    }).catch((error) => {
                        dispatch('error', { error, reject });
                    });
                });
            },
            fetch({ commit, getters, state, rootState }) {
                return new Promise((resolve, reject) => {
                    if (state.loaded) {
                        resolve(state.items);
                        return;
                    }

                    var url = `/${getters.model}`;

                    axios.get(url).then((ret) => {
                        commit('setAll', ret.data);
                        commit('setLoaded', true);
                        resolve(ret.data);
                    }).catch(err => reject(err));
                });
            },
            update({ getters, commit, dispatch }, values) {
                return new Promise((resolve, reject) => {
                    axios.patch(`/${getters.model}/${values.id}`, values).then((ret) => {
                        dispatch('messages/success', getters.messages.updated, { root: true });
                        commit('update', ret.data);
                        resolve(ret);
                    }).catch((error) => {
                        dispatch('error', { error, reject });
                    });
                });
            },

            error({ getters, commit, dispatch }, { error, reject }) {
                if (!error.response) {
                    reject(error);
                    return;
                }

                if (error.response.status === 403) {
                    dispatch('messages/danger', 'Hierzu fehlt Ihnen die notwendige Berechtigung.', { root: true });
                    reject(error);
                    return;
                }

                Object.keys(error.response.data).forEach((field) => {
                    error.response.data[field].forEach((message) => {
                        dispatch('messages/danger', message, { root: true });
                    });
                });

                reject(error);
            }
        }
    };
};
