<template>
    <div>
        <div class="p-6">
            <div class="flex items-center">
                <label for="search" class="text-xs text-gray-800 mr-2">Suchen</label>
                <input type="text" name="search" v-model="searchValue" class="p-2 text-sm bg-gray-200">
            </div>
        </div>

        <v-table :headers="[
            {text: 'Vorname', value: 'firstname', align: 'left'},
            {text: 'Nachname', value: 'lastname', align: 'left'},
            {text: 'Addresse', value: 'address', align: 'left'},
            {text: 'PLZ', value: 'zip', align: 'left'},
            {text: 'Ort', value: 'location', align: 'left'}
            ]"
            :items="data.data"
            :sort="sortValue"
            @sort="sortValue = $event"
            actions
        >
            <template slot="Vorname" slot-scope="{ item }">
                {{item.firstname}}
            </template>

            <template slot="Nachname" slot-scope="{ item }">
                {{item.lastname}}
            </template>

            <template slot="Addresse" slot-scope="{ item }">
                {{item.address}}
            </template>

            <template slot="PLZ" slot-scope="{ item }">
                {{item.zip}}
            </template>

            <template slot="Ort" slot-scope="{ item }">
                {{item.location}}
            </template>

            <template slot="actions" slot-scope="{ item }">
                <goto :href="item.links.edit" icon="pencil" class="btn btn-primary ml-1 text-white"></goto>
                <goto :href="item.links.delete" icon="calendar" class="btn btn-primary ml-1 text-white"></goto>
            </template>

        </v-table>
        
        <pagination @goto="pageValue = $event" :value="data.meta"></pagination>
    </div>
</template>

<script>
import App from '../../layouts/App';
import Pagination from '../../components/Pagination.vue';
import { debounce } from 'lodash';

export default {
    
    layout: App,

    components: { Pagination },

    props: { search: {}, page: {}, data: {}, sort: {}, direction: {} },

    computed: {
        searchValue: {
            get() {
                return this.search;
            },
            set: debounce(function(v) {
                this.$inertia.visit(window.location.origin + window.location.pathname, {
                    data: { page: this.page, search: v, sort: this.sort, direction: this.direction },
                    preserveScroll: true,
                    preserveState: true
                });
            }, 500)
        },
        pageValue: {
            get() {
                return this.page;
            },
            set(v) {
                this.$inertia.visit(window.location.origin + window.location.pathname, {
                    data: { page: v, search: this.search, sort: this.sort, direction: this.direction },
                    preserveScroll: true,
                    preserveState: true
                });
            }
        },
        sortValue: {
            get() {
                return {
                    key: this.sort,
                    direction: this.direction
                };
            },
            set(v) {
                this.$inertia.visit(window.location.origin + window.location.pathname, {
                    data: { page: this.page, search: this.search, sort: v.key, direction: v.direction },
                    preserveScroll: true,
                    preserveState: true
                });
            }
        }
    }
};
</script>
