var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu shadow list-reset"},_vm._l((_vm.menu),function(entry,index){return _c('li',{key:index,staticClass:"mt-2"},[(!_vm.hasSubmenu(index))?_c('a',{staticClass:"p-2 text-sm menu-entry block no-underline text-black font-semibold",class:{
                'bg-primary text-white': _vm.value == entry
            },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('input', entry)}}},[_vm._v(_vm._s(entry))]):_c('a',{staticClass:"p-2 text-sm menu-entry block no-underline text-black font-semibold",class:{
                'bg-grey-lightest': _vm.isOpen(index)
            },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.open(index)}}},[_vm._v(_vm._s(entry.entry))]),_vm._v(" "),(_vm.hasSubmenu(index))?_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen(index)),expression:"isOpen(index)"}],staticClass:"list-reset bg-grey-lightest"},_vm._l((entry.submenu),function(submenu,i){return _c('li',{key:i,staticClass:"flex items-center p-1",class:{
                    'border-l-2 border-primary border-solid': _vm.value == submenu,
                    'border-l-2 border-transparent border-solid': _vm.value != submenu
                }},[_c('svg',{staticClass:"w-3 h-3 fill-curent ml-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"}})]),_vm._v(" "),_c('a',{staticClass:"pl-2 text-sm menu-entry block no-underline text-black",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('input', submenu)}}},[_vm._v(_vm._s(submenu))])])}),0):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }