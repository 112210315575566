<template>
    <form action="#" @submit.prevent="sub" method="post" class="grid grid-cols-2 p-6 gap-6">
        <div>
            <f-switch v-model="hasZipFilter" label="Nach PLZ filtern"></f-switch>
            <f-text v-if="hasZipFilter" v-model="data.zip" label="Erste PLZ-Stellen" hint="Erste Stellen der PLZ eingeben, die gematcht werden sollen - z.B. 426"></f-text>
        </div>
        <div>
            <f-switch v-model="hasMinuteFilter" label="Nach letzten Minuten filtern"></f-switch>
            <f-text v-if="hasMinuteFilter" v-model="data.min_ago" label="Letzte Minuten" hint="Anzahl in Minuten, die eine Anfrage maximal zurück liegen darf"></f-text>
        </div>
        <div>
            <f-switch v-model="hasAreaFilter" label="Nach Wohnfläche filtern"></f-switch>
            <v-nus v-if="hasAreaFilter" :value="[data.area_from, data.area_to]" :config="configArea" @update="updateArea"></v-nus>
        </div>
        <div>
            <f-switch v-model="hasPriceFilter" label="Nach Preis filtern"></f-switch>
            <v-nus v-if="hasPriceFilter" :value="[data.price_from, data.price_to]" :config="configPrice" @update="updatePrice"></v-nus>
        </div>
        <div>
            <button type="submit" class="btn btn-primary">Speichern</button>
        </div>
        <div class="col-span-2">
            <v-table :headers="[
                {text: 'Vorname', value: 'firstname', align: 'left'},
                {text: 'Nachname', value: 'lastname', align: 'left'},
                {text: 'Addresse', value: 'address', align: 'left'},
                {text: 'PLZ', value: 'zip', align: 'left'},
                {text: 'Ort', value: 'location', align: 'left'}
                ]"
                :items="feedbacks.data"
            >
                <template slot="Vorname" slot-scope="{ item }">
                    {{item.inquiry.firstname}}
                </template>

                <template slot="Nachname" slot-scope="{ item }">
                    {{item.inquiry.lastname}}
                </template>

                <template slot="Addresse" slot-scope="{ item }">
                    {{item.inquiry.address}}
                </template>

                <template slot="PLZ" slot-scope="{ item }">
                    {{item.inquiry.zip}}
                </template>

                <template slot="Ort" slot-scope="{ item }">
                    {{item.inquiry.location}}
                </template>

            </v-table>

            <pagination @goto="pageValue = $event" :value="feedbacks.meta"></pagination>

        </div>

    </form>
</template>

<script>
import App from '../../layouts/App';
import Pagination from '../../components/Pagination.vue';

class PriceFormatter {
    constructor(reference, diff) {
        this.reference = reference;
        this.diff = diff;
    }

    valueFormat() {
        return {
            to: function (value) {
                return value;
            },
            // 'from' the formatted value.
            // Receives a string, should return a number.
            from: function (value) {
                return Number(value);
            }
        };
    }

    filterPips(min, max, reference, diff) {
        return function(value, type) {
            if (reference == value) { return 1; }
            if (value % 10000000 == 0) { return 2; }
            return value % 1000000 == 0 ? 0 : -1;
        };
    }

    pipFormat() {
        return {
            to: function (value) {
                return (value / 100) + ' €';
            },
            // not used
            from: function (value) {
                return Number(value);
            }
        };
    }

    step() {
        return 10000;
    }
}

class AreaFormatter {
    constructor(reference, diff) {
        this.reference = reference;
        this.diff = diff;
    }

    valueFormat() {
        return {
            to: function (value) {
                return value;
            },
            // 'from' the formatted value.
            // Receives a string, should return a number.
            from: function (value) {
                return Number(value);
            }
        };
    }

    filterPips(min, max, reference, diff) {
        return function(value, type) {
            if (reference == value) { return 1; }
            if (value % 10000 == 0) { return 2; }
            return value % 1000 == 0 ? 0 : -1;
        };
    }

    pipFormat() {
        return {
            to: function (value) {
                return (value / 100) + ' m²';
            },
            // not used
            from: function (value) {
                return Number(value);
            }
        };
    }

    step() {
        return 100;
    }
}

function mixin(source, reference, diff, formatter) {
    var formatter = new formatter(reference, diff);

    var trait = {
        computed: {},
        methods: {}
    };

    var ucfirst = source.charAt(0).toUpperCase() + source.slice(1);
    trait.computed[`has${ucfirst}Filter`] = {
        get() {
            return this.data[`${source}_from`] != null && this.data[`${source}_to`] != null;
        },
        set(v) {
            if (v) {
                this.data[`${source}_from`] = getConfig(this.data[reference], diff, formatter).range.min[0];
                this.data[`${source}_to`] = getConfig(this.data[reference], diff, formatter).range.max[0];
            } else {
                this.data[`${source}_from`] = null;
                this.data[`${source}_to`] = null;
            }
        }
    };

    trait.computed[`config${ucfirst}`] = function() {
        return getConfig(this.data[reference], diff, formatter);
    };

    trait.methods[`update${ucfirst}`] = function(v) {
        this.data[`${source}_from`] = v[0];
        this.data[`${source}_to`] = v[1];
    }

    return trait;
}

function getConfig(reference, diff, formatter) {
    var min = Math.max(0, reference - diff);
    var max = reference + diff;

    return {
        step: formatter.step(),
        format: formatter.valueFormat(),
        connect: [false, true, false],
        connectColors: ["blue", "blue", "blue"],
        range: {
            min: [min],
            max: [max]
        },
        pips: {
            mode: 'steps',
            filter: formatter.filterPips(min, max, reference, diff),
            density: 4,
            format: formatter.pipFormat()
        }
    };
}

export default {
    data: function() {
        return {
        };
    },

    mixins: [
        mixin('area', 'wohnflaeche', 20000, AreaFormatter),
        mixin('price', 'fprice', 20000000, PriceFormatter)
    ],

    computed: {
        hasZipFilter: {
            set(v) {
                this.data.zip = v ? '' : null;
            },
            get() {
                return this.data.zip !== null;
            }
        },
        hasMinuteFilter: {
            set(v) {
                this.data.min_ago = v ? '' : null;
            },
            get() {
                return this.data.min_ago !== null;
            }
        }
    },

    layout: App,

    props: {
        data: {},
        feedbacks: {},
        durations: {}
    },

    components: { Pagination },

    methods: {
        sub() {
            this.$inertia.patch(this.data.links.update, this.data);
        }
    }

};
</script>

