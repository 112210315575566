<template>
    <div class="flex-grow flex flex-col">
        <confirm></confirm>
        <modal></modal>

        <div :class="[headerBg]" class="p-10 flex flex-col" data-cy="header">
            <div class="-mx-3 flex items-center mb-6">
                <div class="w-1/5 px-3">
                    <slot name="brand-icon"></slot>
                </div>
                <div class="w-3/5 px-3 relative">
                    <div class="absolute pin- pin-l flex items-center h-full ml-4 mr-4 text-white-t400">
                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-6 h-6" viewBox="0 0 20 20"><path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/></svg>
                    </div>
                    <input name="search" class="bg-white-t200 shadow pr-5 pl-12 py-4 w-full rounded-sm shadow text-white" v-model="$page.search" :placeholder="$page.searchPlaceholder" @input="onSearch">
                </div>
                <div class="w-1/5 pr-3">
                    <div class="-mx-2 flex justify-end items-center">
                        <toolbar-menu :name="adminToolbar" class="px-2" pin="r" v-if="adminToolbar.length">
                            <svg slot="activator" slot-scope="{open}" @click="open" class="fill-current w-p2 text-white cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M3.94 6.5L2.22 3.64l1.42-1.42L6.5 3.94c.52-.3 1.1-.54 1.7-.7L9 0h2l.8 3.24c.6.16 1.18.4 1.7.7l2.86-1.72 1.42 1.42-1.72 2.86c.3.52.54 1.1.7 1.7L20 9v2l-3.24.8c-.16.6-.4 1.18-.7 1.7l1.72 2.86-1.42 1.42-2.86-1.72c-.52.3-1.1.54-1.7.7L11 20H9l-.8-3.24c-.6-.16-1.18-.4-1.7-.7l-2.86 1.72-1.42-1.42 1.72-2.86c-.3-.52-.54-1.1-.7-1.7L0 11V9l3.24-.8c.16-.6.4-1.18.7-1.7zM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/></svg>
                        </toolbar-menu>
                        <div class="px-2">
                            <svg class="fill-current w-p2 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/></svg>
                        </div>
                        <toolbar-menu :name="profileToolbar" class="px-2" pin="r" v-if="profileToolbar.length">
                            <img :src="$page.auth.user.avatar.src" @click="open" slot-scope="{open}"
                            slot="activator"
                            class="w-p4 h-p4 rounded-full border-2 border-solid border-grey cursor-pointer flex-none" data-cy="profile-pic">
                        </toolbar-menu>
                    </div>
                </div>
            </div>
            <div class="flex-grow -mx-3 flex flex-col justify-end">
                <div class="w-1/5 px-3">
                    <div class="text-white">
                        <slot name="head-info"></slot>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-gray-200 p-10 flex-grow">
            <div class="-mx-3 flex">
                <div class="px-3 w-1/5">
                    <slot name="mainnav"></slot>
                </div>
                <div class="px-3 w-4/5 -mt-24 flex flex-col min-h-full">
                    <div v-if="heading" class="text-base mb-2 text-white font-bold">
                        {{ heading }}
                    </div>
                    <div class="bg-white flex-grow shadow-flat">
                        <slot name="main"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Confirm from 'agnoster/components/Confirm.vue';
import Modal from 'agnoster/components/Modal.vue';
import ToolbarMenu from './ToolbarMenu.vue';
import { debounce } from 'lodash';

export default {
    props: {
        adminToolbar: {
            type: String
        },
        profileToolbar: {
            type: String,
            default: ''
        },
        headerBg: {
            default: 'bg-primary'
        }
    },
    computed: {
        heading() {
            return this.$page.title;
        },
    },
    methods: {
        onSearch(event) {
            this.$inertia.visit(location.pathname, {
                data: { search: event.target.value },
                preserveState: true,
                preserveScroll: true,
                replace: true,
                only: ['data']
            });
        }
    },
    components: { Confirm, ToolbarMenu, Modal }
};
</script>
