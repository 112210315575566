export default {
    props: {
        columns: {
            default: '1fr'
        },
        dense: {
            type: Boolean
        },
        tag: {
            default: 'div'
        },
        fullsize: {
            type: Boolean,
            default: false
        },
        gap: {
            default: 25,
            type: Number
        }
    },
    render(createElement) {
        return createElement(this.$props.tag, {
            style: this.style
        }, this.$slots.default);
    },
    computed: {
        style() {
            var s = {
                'display': 'grid',
                'grid-gap': this.$props.gap + 'px',
                'grid-template-columns': this.$props.columns
            };

            if (!this.fullsize) {
                s['padding'] = this.$props.gap + 'px';
            }

            if (this.dense) {
                s['grid-auto-flow'] = 'dense';
            }

            return s;
        }
    }
};
