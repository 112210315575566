<template>
    <form method="post" @submit.prevent="$inertia.patch(data.links.update, data)">
        <grid>
            <item>
                <tabs v-model="tab">
                    <tab label="Portale">
                        <portals v-model="data.portals"></portals>
                    </tab>
                </tabs>
            </item>

            <item>
                <f-submit primary>Absenden</f-submit>
            </item>
        </grid>
    </form>
</template>

<script>
import Portals from './Portals';
import App from '../../layouts/App';

export default {

    layout: App,

    props: {
        data: {}
    },

    data: function() {
        return {
            tab: 'portale'
        };
    },
    components: { Portals }
};
</script>
