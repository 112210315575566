<template>
    <div style="width: 100%;">
        <ul class="stepper" :class="'stepper-' + activeColor">
            <li v-for="(step, index) in steps" :class="{'current': value == index, 'done': isDone(index)}" :key="index">
                <a href="javascript:void(0);" @click.prevent="changeIndex(index)">
                    <span class="fa step-icon" :class="['fa-' + step.icon]" v-if="typeof step.icon !== 'undefined'"></span>
                    <span class="step-icon" v-if="typeof step.icon === 'undefined'">
                        <slot :name="'icon-'+step.label" v-if="typeof step.icon === 'undefined'"></slot>
                    </span>
                    <span v-html="step.label" class="label step-label"></span>
                </a>
            </li>
        </ul>

        <div v-show="index === value" v-for="(step, index) in steps" :key="index">
            <slot :name="step.label" :next="next" :prev="prev"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {},
        steps: {
            default: function() { return []; },
            type: Array
        },
        activeColor: {
            type: String,
            default: 'default'
        }
    },
    methods: {
        isDone(index) {
            return index < this.value;
        },
        next() {
            if (typeof this.steps[this.value + 1] === 'undefined') { return; }
            this.$emit('input', this.value + 1);
        },
        changeIndex(index) {
            this.$emit('input', index);
        },
        prev() {
            if (typeof this.steps[this.value - 1] === 'undefined') { return; }
            this.$emit('input', this.value - 1);
        }
    }
};
</script>
