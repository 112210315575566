<template>
    <grid rows="1fr" dense fullsize>
        <item><f-text v-model="value.host" label="Host"></f-text></item>
        <item><f-text v-model="value.username" label="Benutzername"></f-text></item>
        <item><f-password v-model="value.password" label="Passwort"></f-password></item>
        <item><f-text v-model="value.root" label="Verzeichnis"></f-text></item>
    </grid>
</template>

<script>
export default {
    
    props: {
        value: {}
    }

};
</script>
