<template>
    <form action="#" @submit.prevent="sub" method="post">
        <grid columns="1fr 1fr">
            <item>
                <f-select v-model="data.duration_id" :options="durations" label="Intervall" required></f-select>
            </item>
            <item>
                <f-switch v-model="data.timer_active" label="Timo Timer aktivieren"></f-switch>
            </item>
            <item>
                <f-switch v-model="data.calendar_active" label="Kalender aktivieren"></f-switch>
            </item>
            <item>
                <f-switch v-model="data.show_address" label="Adresse anzeigen"></f-switch>
            </item>
            <item>
                <button type="submit" class="btn btn-primary">Speichern</button>
            </item>
        </grid>
    </form>
</template>

<script>
import App from '../../layouts/App';

export default {
    layout: App,

    props: {
        data: {},
        durations: {}
    },

    methods: {
        sub() {
            this.$inertia.patch(this.data.links.update, this.data);
        }
    }

};
</script>

