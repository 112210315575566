var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-table',{attrs:{"headers":[
        {text: 'ObjektId', value: 'objekt_id', align: 'left'},
        {text: 'Titel', value: 'title', align: 'left'},
        {text: 'Addresse', value: 'address', align: 'left'},
        {text: 'PLZ', value: 'zip', align: 'left'},
        {text: 'Ort', value: 'location', align: 'left'},
        {text: 'TimoTimer', value: 'timer', align: 'left', sortable: false} ],"items":_vm.data,"actions":""},scopedSlots:_vm._u([{key:"ObjektId",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.objekt_id)+"\n        ")]}},{key:"Titel",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.title)+"\n        ")]}},{key:"Addresse",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.address)+"\n        ")]}},{key:"PLZ",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.zip)+"\n        ")]}},{key:"Ort",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.location)+"\n        ")]}},{key:"TimoTimer",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"btn-icongroup"},[_c('button',{class:_vm.btnBg('timer_active', item),attrs:{"type":"button"},on:{"click":function($event){return _vm.sw('timer_active', item)}}},[_c('span',{staticClass:"fa fa-check"})]),_vm._v(" "),_c('button',{class:_vm.btnBg('calendar_active', item),attrs:{"type":"button"},on:{"click":function($event){return _vm.sw('calendar_active', item)}}},[_c('span',{staticClass:"fa fa-calendar"})]),_vm._v(" "),_c('button',{class:_vm.btnBg('show_address', item),attrs:{"type":"button"},on:{"click":function($event){return _vm.sw('show_address', item)}}},[_c('span',{staticClass:"fa fa-address-card"})])])]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [_c('goto',{staticClass:"btn btn-primary ml-1 text-white",attrs:{"href":item.timer.links.edit,"icon":"pencil"}}),_vm._v(" "),_c('goto',{staticClass:"btn btn-primary ml-1 text-white",attrs:{"href":item.timer.links.events.index,"icon":"calendar"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }