<template>
    <div class="flex flex-col h-full">
        <div class="flex-none"><f-text v-model="value.title" label="Titel"></f-text></div>
        <div class="flex-grow">
            <tabs v-model="tab" stretch>
                <tab label="Beschreibung" class="h-full">
                    <f-textarea v-model="value.description" label="Beschreibung" :rows="10" class="flex-grow" field-class="h-full"></f-textarea>
                </tab>
                <tab label="Lage" class="h-full">
                    <f-textarea v-model="value.lage" label="Lage" :rows="10" class="flex-grow" field-class="h-full"></f-textarea>
                </tab>
                <tab label="Ausstattung" class="h-full">
                    <f-textarea v-model="value.ausstattung" label="Ausstattung" :rows="10" class="flex-grow" field-class="h-full"></f-textarea>
                </tab>
                <tab label="Sonstiges" class="h-full">
                    <f-textarea v-model="value.sonstiges" label="Sonstiges" :rows="10" class="flex-grow" field-class="h-full"></f-textarea>
                </tab>
            </tabs>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            tab: 'beschreibung'
        };
    },

    props: {
        value: {
            default() {
                return {
                    description: '',
                    lage: '',
                    ausstattung: '',
                    sonstiges: ''
                };
            }
        }
    }
};
</script>
