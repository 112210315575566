<template>
    <div>
        <f-checkbox label="Importiere Anfragen" v-model="requestimport"></f-checkbox>

        <grid v-if="requestimport" fullsize>
            <item><f-text v-model="value.host" label="Email-Host"></f-text></item>
            <item><f-text v-model="value.username" label="Email-Benutzer"></f-text></item>
            <item><f-password v-model="value.password" label="Email-Passwort"></f-password></item>
            <item v-if="typeof value.mailboxes !== 'undefined' && value.mailboxes.length"><f-select v-model="value.mailbox" :options="value.mailboxes" label="Mailbox"></f-select></item>
        </grid>

    </div>
</template>

<script>
export default {
    
    props: {
        value: {}
    },

    computed: {
        requestimport: {
            set(v) {
                this.$emit('input', v ? {} : null);
            },
            get() {
                return this.value !== null;
            }
        }
    }

};
</script>
