export default function(links, canVisitLink) {
    return {
        namespaced: true,
        state: links,

        getters: {
            links: (state, getters, rootState, rootGetters) => (key) => {
                return state[key].links.filter((link) => {
                    return link.right === undefined || canVisitLink === undefined || canVisitLink(link, { state, getters, rootState, rootGetters }) === true;
                });
            },

            opened: (state) => (key) => {
                return state[key].open;
            }
        },

        mutations: {
            setState(state, { key, status }) {
                state[key].open = status;
            }
        }
    };
};
