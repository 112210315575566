<template>
    <div class="p-6">
        <form @submit.prevent= "$inertia.patch(value.links.update, value)">
            <f-text v-model="value.title" label="Titel"></f-text>
            <f-select v-model="value.type_id" label="Typ" :options="types"></f-select>
            
            <div v-if="component !== null">
                <v-component :is="component" v-model="value.credentials"></v-component>
            </div>

            <f-submit class="mt-2" primary>Absenden</f-submit>
        </form>
    </div>
</template>

<script>
import App from '../../layouts/App';
import resolveComponent from './resolveComponent.mixin.js';

export default {

    layout: App,

    mixins: [ resolveComponent ],

    props: {
        types: {},
        value: {}
    },

};
</script>
