<template>
    <div id="app" class="font-sans flex flex-col flex-grow">
        <notification :errors="$page.errors"></notification>
        <fullpage>
            <img slot="brand-icon" src="/img/next-immobilien-weiss.png" class="w-64 flex-grow">
            <slot />
            <div slot="footer" slot-scope="{ cls }" :class="cls" class="py-2 flex justify-btween bg-primary-lightest justify-center items-center">
                <a href="https://next-immobilien.de/impressum/" target="_BLANK" class="text-primary-darker no-underline text-xs">Impresssum</a>
                <span class="text-3xl text-primary-darker mx-1">&middot;</span>
                <a href="https://next-immobilien.de/datenschutzerklaerung/" target="_BLANK" class="text-primary-darker no-underline text-xs">Datenschutzerklärung</a>
            </div>
        </fullpage>
    </div>
</template>

<script>
import Notification from 'agnoster/components/Notification.vue';
import Fullpage from 'agnoster/components/Fullpage.vue';

export default {
    computed: {
        title() {
            return this.$store.getters.title;
        }
    },
    components: { Notification, Fullpage }
};
</script>
