<template>
    <label :for="unique" class="field-wrapper focused">
        <span class="label-font label-placeholder" v-if="label">{{ label }}</span>
        <span class="label-font label-placeholder" v-if="emptyLabel"></span>
        <div class="relative">
            <select :id="unique" :value="value" @change="trigger"
                class="form-control form-control-outline appearance-none w-full" :data-cy="dataCy"
            >
                <option v-if="placeholder" v-html="placeholder" :value="null"></option>

                <option v-for="(option, index) in parsedOptions" :key="index"
                    v-html="option.title" :value="option.id"
                ></option>
            </select>
            <a class="absolute right-0 top-0 flex items-center cursor-pointer h-full mr-8" @click="clear" v-if="value != null && !required">
                <svg class="w-3 h-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/></svg>
            </a>
        </div>
    </label>
</template>

<script>
import FormField from './FormField.js';

export default {
    extends: FormField,
    props: {
        emptyLabel: {
            default: false,
            type: Boolean
        },
        value: {
            default: undefined
        },
        label: {
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            default: '',
            type: String
        },
        def: {
            required: false,
            type: Number,
            default: -1
        },
        options: {
            default: function() { return []; }
        }
    },
    computed: {
        parsedOptions() {
            return this.options.map((option) => {
                if (typeof option === 'object') { return option; }

                return {
                    title: option,
                    id: option
                };
            });
        }
    },
    methods: {
        trigger(v) {
            this.$emit('input', isNaN(parseInt(v.target.value)) ? v.target.value : parseInt(v.target.value));
        },
        clear() {
            this.$emit('input', null);
        }
    },
    mounted() {
        if (this.def !== -1 && typeof this.value === 'undefined') {
            this.$emit('input', this.def);
            return;
        }

        if (this.placeholder && typeof this.value === 'undefined') {
            this.$emit('input', null);
        }
    }
};
</script>
