<template>
    <ul class="menu shadow list-reset">
        <li :key="index" v-for="(entry, index) in menu" class="mt-2">
            <a href="#" @click.prevent="$emit('input', entry)"
                class="p-2 text-sm menu-entry block no-underline text-black font-semibold"
                :class="{
                    'bg-primary text-white': value == entry
                }"
                v-if="!hasSubmenu(index)"
            >{{ entry }}</a>
            <a href="#" @click.prevent="open(index)"
                class="p-2 text-sm menu-entry block no-underline text-black font-semibold"
                :class="{
                    'bg-grey-lightest': isOpen(index)
                }"
                v-else
            >{{ entry.entry }}</a>
            <ul v-if="hasSubmenu(index)" v-show="isOpen(index)" class="list-reset bg-grey-lightest">
                <li :key="i" v-for="(submenu, i) in entry.submenu" class="flex items-center p-1"
                    :class="{
                        'border-l-2 border-primary border-solid': value == submenu,
                        'border-l-2 border-transparent border-solid': value != submenu
                    }">
                    <svg class="w-3 h-3 fill-curent ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
                    <a href="#" @click.prevent="$emit('input', submenu)"
                        class="pl-2 text-sm menu-entry block no-underline text-black"
                    >{{ submenu }}</a>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
export default {
    data: function() {
        return {
            menu: [
                'Objektart',
                {
                    entry: 'Objektangaben',
                    submenu: [ 'Eigentümerdaten', 'Objektdaten', 'Grundbuchdaten', 'Käuferdaten' ]
                },
                {
                    entry: 'Objektdetails',
                    submenu: [ 'Grundstück', 'Objekt', 'Sanierungen', 'Energieausweis', 'Anlageobjekt', 'Gewerbeobjekt' ]
                },
                'Betriebskosten und Einnahmen',
                {
                    entry: 'Ausstattung & Zustand',
                    submenu: [ 'Wohnen', 'Gastro', 'Gewerbe', 'Extras' ]
                },
                'Status',
                'Preise & Provision',
                'Beschreibung',
                'Fotos',
                'Social Media',
                'Portale',
                'Exposéeinstellungen',
                'Notar'
            ],
            openIndexes: []
        };
    },
    props: {
        value: {}
    },
    methods: {
        open(index) {
            if (this.isOpen(index)) {
                this.openIndexes = this.openIndexes.slice(index, 1);
            } else {
                this.openIndexes.push(index);
            }
        },
        isOpen(index) {
            return this.openIndexes.find(e => e === index) !== undefined;
        },
        hasSubmenu(index) {
            return typeof this.menu[index] === 'object';
        }
    }
};
</script>

