<template>
    <form @submit.prevent="submit">
        <grid fullsize>
            <item>
                <f-text label="E-Mail-Adresse" v-model="values.email" data-cy="email"></f-text>
            </item>

            <item>
                <f-password label="Passwort" v-model="values.password" data-cy="password"></f-password>
            </item>

            <item>
                <f-submit data-cy="submit" primary>Login</f-submit>
            </item>
        </grid>
    </form>
</template>

<script>
import Full from '../layouts/Full';

export default {
    layout: Full,

    data: function() {
        return {
            values: {}
        };
    },
    methods: {
        submit() {
            this.$inertia.post('/login', this.values);
        }
    }
};
</script>
