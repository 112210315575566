<template>
    <elly-form @submit="$inertia.patch(data.links.update, data)"
        v-model="data"
        :portals="portals"
        :categories="categories"
        :marketingkinds="marketingkinds"
        :countries="countries"
        :regions="regions"
        updating
    ></elly-form>
</template>

<script>
import EllyForm from './EllyForm';
import App from '../../layouts/App';

export default {
    layout: App,

    props: {
        data: {},
        categories: {},
        marketingkinds: {},
        countries: {},
        regions: {},
        portals: {}
    },

    components: { EllyForm }

};
</script>

