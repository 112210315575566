<template>
    <form class="relative" @submit.prevent="submit">
        <a href="#" class="text-primary-darker" @click.prevent="open=!open" flat><span class="fa fa-pencil fa-lg"></span></a>
        <div class="absolute popup top-0 left-0 shadow-lg bg-gray-400 rounded p-3" v-show="open">
            <input type="datetime-local" :step="duration" v-model="start" class="bg-gray-400 text-sm">
            <input type="datetime-local" :step="duration" v-model="end" class="bg-gray-400 text-sm">
            <button type="submit" class="btn btn-sm btn-primary">Absenden</button>
        </div>
    </form>
</template>

<script>
export default {

    data: function() {
        return {
            open: false,
            inner: {}
        };
    },

    methods: {
        submit() {
            this.$emit('input', this.inner);
            this.open = false;
        }
    },

    computed: {
        start: {
            get() {
                return this.inner.starts_at.replace(' ', 'T');
            },
            set(v) {
                this.inner.starts_at = v.replace('T', ' ');
            }
        },
        end: {
            get() {
                return this.inner.ends_at.replace(' ', 'T');
            },
            set(v) {
                this.inner.ends_at = v.replace('T', ' ');
            }
        }
    },
    
    props: {
        duration: {},
        value: {}
    },

    created() {
        this.inner = { starts_at: this.value.starts_at, ends_at: this.value.ends_at };
    }
};
</script>

<style scope>
.popup {
    width: 200px;
    margin-left: -100px;
    left: 50%;
    top: 100%;
}
</style>
