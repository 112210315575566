<template>
    <div class="fixed top-0 pin-l h-screen w-screen z-30 flex items-center justify-center bg-black-transparent" v-if="any">
        <div class="bg-white shadow rounded-sm p-6 text-center" v-for="(item, index) in items" :key="index">
            <svg class="fill-current h-16 w-16 mx-auto" :class="iconColor(item)" v-if="item.icon && item.icon == 'information-outline'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
            <div v-html="item.title" class="p-3 text-base font-aweos mt-2"></div>
            <div v-html="item.message" class="mt-1 text-xs font-aweos text-grey-darker"></div>
            <div class="flex justify-center mt-5">
                <button type="button" :class="['bg-'+btn.bgClass, 'text-'+btn.textClass]"
                    class="btn shadow ml-1" v-html="btn.label" @click="btn.click(index)"
                    :key="btnIndex" v-for="(btn, btnIndex) in item.buttons"
                ></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        items() {
            return this.$store.getters['confirm/all'];
        },
        any() {
            return this.$store.getters['confirm/any'];
        }
    },
    methods: {
        iconColor(item) {
            if (!item.iconColor) { return ''; }

            return 'text-' + item.iconColor;
        }
    }
};
</script>

<style scoped>
    /* @todo Add transparent colors as a tailwind plugin */
    /* @todo change font-aweos to some more general classname like 'font-highlight'.
             Add this behaviour to tailwind
    */
    .bg-black-transparent {
        background: rgba(0, 0, 0, 0.3);
    }
</style>
