<template>
    <div>
        <slot name="before"></slot>
        <div class="flex justify-end mt-6">
            <goto @click.prevent="prevWeek" class="btn btn-primary btn-sm mr-1" dark>Zurück</goto>
            <goto @click.prevent="nextWeek" class="btn btn-primary btn-sm" dark>Vor</goto>
        </div>

        <table width="100%" class="calendar-table mt-6" cellpadding="0" cellspacing="0">
            <thead>
                <th class="border-b border-r border-gray-500 border-t border-l font-semibold">Zeit</th>
                <th v-for="day, index in days" class="border-b border-t border-r border-gray-500 font-semibold">
                    {{ day.format('DD. MMMM') }}
                </th>
            </thead>
            <tbody>
                <tr v-for="(row, index) in smallRows">
                    <td :rowspan="(duration / 5)" v-if="index % (duration / 5) == 0" class="border-b border-l border-r border-gray-500">{{ row }}</td>
                    <td v-for="day in days" align="center" class="border-b border-r border-gray-500 h-6 relative trigger-event-hover">
                        <slot :day-event="dayEvent" :day-event-index="dayEventIndex" name="eventButton" v-for="(dayEvent, dayEventIndex) in eventsThatStartWith(day, row)">
                            <div class="absolute top-0 left-0 rounded items-center justify-center bg-primary shadow-lg w-full add cursor-pointer flex flex-col"
                                :class="[dayEvent.isBooked ? 'red' : 'green', {editable: 'editable'}]"
                                @click="$emit('eventClick', dayEvent)"
                                :key="dayEventIndex"
                                slot="activator"
                                style="position: absolute; z-index: 5;"
                                :style="{height: (eventDuration(dayEvent) / 5 * 25)+'px'}"
                            >
                                <div>
                                    <small>{{ dayEvent.propertyObjektId }}</small>
                                </div>
                                <div class="flex justify-evenly items-center w-full">
                                    <edit-event :duration="eventDuration(dayEvent)" :value="dayEvent" @input="$emit('editevent', { ...dayEvent, ...$event })" v-if="editable"></edit-event>
                                    <a href="#" class="text-primary-darker" v-if="infoable && dayEvent.isBooked" @click="$emit('info', dayEvent)" flat><span class="fa fa-info fa-lg"></span></a>
                                    <a href="#" class="text-primary-darker" v-if="deleteable" @click="$emit('deleteevent', dayEvent)" flat><span class="fa fa-trash fa-lg"></span></a>
                                </div>
                            </div>
                        </slot>

                        <button
                            v-if="eventsThatStartWith(day, row).length == 0 && createable"
                            class="absolute top-0 left-0 on-event-hover rounded items-center justify-center bg-primary shadow-lg w-full"
                            type="button"
                            @click="addevent(day, row)"
                            data-ripple="true"
                            draggable="true"
                            @dragstart="setEvent(day, row, $el)"
                            style="position: absolute; z-index: 4;"
                            :style="{height: (duration / 5 * 25)+'px'}"
                        >
                            <span class="fa fa-plus text-white"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment';
moment.locale('de');
import $ from 'jquery';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/selectable.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/draggable';
import EditEvent from './EditEvent.vue';

export default {
    data: function() {
        return  {
            day: moment().format('YYYY-MM-DD')
        };
    },
    props: {
        value: {
            required: true,
            default: []
        },
        duration: {
            default: 15
        },
        createable: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        deleteable: {
            type: Boolean,
            default: false
        },
        infoable: {
            type: Boolean,
            default: false
        }
    },
    components: { EditEvent },
    computed: {
        startOfWeek: function() {
            var day = moment(this.day);

            while (day.day() != 1) {
                day.subtract(1, 'day');
            }

            return day;
        },
        endOfWeek: function() {
            var day = moment(this.day);

            while (day.day() != 0) {
                day.add(1, 'day');
            }

            return day;
        },
        rows: function() {
            var ret = [];
            var start = moment.duration(9*60*60*1000);
            while(start.asSeconds() <= 18*60*60) {
                var minutes = (parseInt(start.minutes()) >= 10) ? start.minutes() : '0'+start.minutes();
                var hours = (parseInt(start.hours()) >= 10) ? start.hours() : '0'+start.hours();
                ret.push(hours+':'+minutes);
                start.add(this.duration, 'm');
            }

            return ret;
        },
        smallRows: function() {
            var ret = [];
            var start = moment.duration(9*60*60*1000);
            while(start.asSeconds() <= 18*60*60) {
                var minutes = (parseInt(start.minutes()) >= 10) ? start.minutes() : '0'+start.minutes();
                var hours = (parseInt(start.hours()) >= 10) ? start.hours() : '0'+start.hours();
                ret.push(hours+':'+minutes);
                start.add(5, 'm');
            }

            return ret;
        },
        days: function() {
            var day = this.startOfWeek.clone();

            var ret = [];
            var i = 0;

            while(day.format('YYYY-MM-DD') != this.endOfWeek.format('YYYY-MM-DD') && i < 20) {
                ret.push(day.clone());
                day.add(1, 'day');
                i++;
            }

            ret.push(day.clone());

            return ret;
        }
    },
    methods: {
        prevWeek: function() {
            this.day = moment(this.day).subtract(7, 'days').format('YYYY-MM-DD');
        },
        nextWeek: function() {
            this.day = moment(this.day).add(7, 'days').format('YYYY-MM-DD');
        },
        eventsThatStartWith: function(day, time) {
            var times = time.split(':');
            var from = day.clone().add(times[0], 'hours').add(times[1], 'minutes').format('YYYY-MM-DD HH:mm:ss');
            return this.value.filter(function(e) {
                return e.starts_at == from;
            });
        },
        setEvent: function(day, row, el) {
            console.log('EL');
        },
        addevent: function(day, time) {
            var vm = this;

            var times = time.split(':');

            var starts_at = day.clone().add(times[0], 'hours').add(times[1], 'minutes').format('YYYY-MM-DD HH:mm:ss');
            var ends_at = day.clone().add(times[0], 'hours').add(times[1], 'minutes').add(this.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss');

            this.$emit('addevent', { starts_at: starts_at, ends_at: ends_at });
        },
        eventDuration(dayEvent) {
            return moment.duration(moment(dayEvent.ends_at).diff(dayEvent.starts_at)).asMinutes();
        }
    },
    mounted: function() {
        console.log('UUU');
        $('.table-button-add').on('dragstart', function() {
            console.log('II');
        });
        $('.event-cell').on('dragenter', () => {
            console.log('dragged');
        });
    }
};
</script>

<style>
.trigger-event-hover .on-event-hover {
    display: none;
}
.trigger-event-hover:hover .on-event-hover {
    display: flex;
}
</style>

