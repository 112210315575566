<template>
    <div class="relative p-6">
        <toolbar>
            <goto :href="data.meta.links.create" icon="plus" class="text-white">Objekt anlegen</goto>
        </toolbar>

        <v-table :headers="[
            {text: 'Name', value: 'name', align: 'left'},
            {text: 'E-Mail-Adresse', value: 'email', align: 'left'},
            ]"
            :items="data.data"
            actions
        >
            <template slot="Name" slot-scope="{ item }">
                {{item.firstname}}
            </template>

            <template slot="E-Mail-Adresse" slot-scope="{ item }">
                {{item.email}}
            </template>

            <template slot="actions" slot-scope="{ item }">
                <goto :href="item.links.edit" icon="pencil" class="btn btn-primary ml-1 text-white"></goto>
                <goto  @click="destroy(item)" icon="trash" class="btn btn-danger ml-1 text-white"></goto>
            </template>
        </v-table>
    </div>
</template>

<script>
import App from '../../layouts/App';

export default {
    layout: App,

    props: {
        data: {}
    }

};
</script>

