<template>
    <div>
        <div v-for="(entry, index) in entries" :key="index">
            <component :is="entry.tag" class="-ml-10 pl-10 pr-3 flex items-center hover:bg-gray-300 no-underline text-gray-700"
                :href="entry.href"
                :key="index"
                target="_BLANK"
                :class="{
                    'py-5': !sm,
                    'py-2': sm
                }"
            >
                <span class="fa pr-2" :class="'fa-'+entry.icon"></span>
                <span class="pl-2 flex-grow text-sm font-bold">
                    {{ entry.title }}
                </span>
            </component>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String
        },
        sm: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        entries() {
            return this.$store.getters['nav/links'](this.name).map(link => {
                return { ...link, tag: link.href.match(/^https?:\/\//) ? 'a' : 'inertiaLink' };
            });
        }
    },

    methods: {
        routeFrom(entry) {
            if ('href' in entry) {
                return { path: entry.href };
            }
            if ('route' in entry) {
                return { name: entry.route };
            }
        }
    }

};
</script>
