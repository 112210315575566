<template>
    <div class="field-wrapper focused">
        <label :for="unique" class="fancy-radio">
            <input type="radio" v-model="v" :value="value" :id="unique" />
            <span :class="['tw-bg-primary']"></span>
            <span v-if="label" class="w-full">{{ label }}</span>
            <span v-if="$slots.default" class="w-full aside-info"><slot></slot></span>
        </label>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'item',
        event: 'input'
    },
    props: {
        value: {
            default: false
        },
        label: {
            default: false
        },
        item: {
            default: undefined
        }
    },
    computed: {
        v: {
            set(v) {
                this.$emit('input', v);
            },
            get() {
                return this.item;
            }
        },
        unique() {
            return Math.random().toString(36).substr(2, 9) +
                Math.random().toString(36).substr(2, 9)
            ;
        }
    }
};
</script>
