<template>
    <div class="py-4 px-7 -mx-4 flex items-center justify-between flex-wrap text-grey-darkest text-sm font-normal text-left">
        <div class="flex px-4" :class="{'invisible': isOnePage}">
            <a class="p-2 rounded-full inline-block cursor-pointer"
                @click="prevPage"
                :class="{
                    'bg-grey-light cursor-not-allowed': onFirstPage,
                    'bg-grey-light hover:bg-gray-200': !onFirstPage,
                    'w-10 h-10': !small, 'w-8 h-8': small
                }"
            >
                <svg :class="{'text-grey-darker': !onFirstPage, 'text-grey-dark': onFirstPage}" class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z"/></svg>
            </a>
            <a class="p-2 ml-2 flex items-center justify-center leading-none rounded-full inline-block cursor-pointer bg-primary"
                v-for="p in pageRange" @click="toPage(p)" :key="p" :class="{ 'text-white-4t': p !== page, 'text-white-8t': p === page, 'w-10 h-10': !small, 'w-8 h-8': small
                }"
            >{{ p }}</a>
            <a class="p-2 rounded-full inline-block ml-2 cursor-pointer"
                @click="nextPage"
                :class="{
                    'bg-grey-light cursor-not-allowed': onLastPage,
                    'bg-grey-light hover:bg-gray-200': !onLastPage,
                    'w-10 h-10': !small, 'w-8 h-8': small
                }"
            >
                <svg class="fill-current" :class="{'text-grey-darker': !onLastPage, 'text-grey-dark': onLastPage}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
            </a>
        </div>
        <div class="px-4" v-if="onepageable"><a href="#" @click.prevent="switchOnePageMode" class="btn btn-primary" :class="{'btn-sm': !small, 'btn-xs': small}" v-html="onePageButtonText"></a></div>
        <div v-if="range" class="px-4 flex-grow flex items-center justify-end text-grey-dark">
            Seite {{ page }} von {{ pages }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        getter: {
            required: true
        },
        range: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: false
        },
        onepageable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        onePageButtonText() {
            return this.isOnePage ? 'Seiten anzeigen' : 'Alles anzeigen';
        },
        isOnePage() {
            return this.$store.getters[this.getter + '/isOnePage'];
        },
        pageRange() {
            return this.$store.getters[this.getter + '/pageRange'];
        },
        pages() {
            return this.$store.getters[this.getter + '/pages'];
        },
        onFirstPage() {
            return this.$store.getters[this.getter + '/onFirstPage'];
        },
        onLastPage() {
            return this.$store.getters[this.getter + '/onLastPage'];
        },
        page: {
            get() {
                return this.$store.getters[this.getter + '/page'];
            },
            set(v) {
                this.$store.commit(this.getter + '/setPage', v);
            }
        }
    },
    methods: {
        switchOnePageMode() {
            this.$store.commit(this.getter + '/switchOnePageMode');
        },
        sortBy(header) {
            if (header.sortable === false) {
                return;
            }

            if (this.sortedBy.value === header.value) {
                this.sortedDirection = !this.sortedDirection;
                return;
            }

            this.sortedDirection = true;
            this.sortedBy = header;
        },
        nextPage() {
            if (this.onLastPage) {
                return;
            }

            this.toPage(this.page + 1);
        },
        prevPage() {
            if (this.onFirstPage) {
                return;
            }

            this.toPage(this.page - 1);
        },
        toPage(page) {
            this.page = page;
        }
    }
};
</script>

