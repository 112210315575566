<template>
    <label class="field-wrapper focused">
        <span :for="unique" class="label-font label-placeholder" v-if="label">{{ label }}</span>
        <input type="password" :id="unique" :value="value" @input="trigger" autocomplete="new-password"
            class="form-control form-control-outline" :data-cy="dataCy"
        >
    </label>
</template>

<script>
import FormField from './FormField.js';

export default {
    extends: FormField,
    props: {
        value: {
            default: ''
        },
        label: {
            default: false
        }
    },
    methods: {
        trigger(v) {
            this.$emit('input', v.target.value);
        }
    }
};
</script>
