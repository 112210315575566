<template>
    <label :for="unique" class="field-wrapper focused">
        <span class="label-font label-placeholder" v-if="label">{{ label }}</span>
        <input type="text" :id="unique" :value="value" @input="trigger"
            class="form-control form-control-outline" :data-cy="dataCy"
            v-mask="mask"
        >
    </label>
</template>

<script>
import FormField from './FormField.js';

export default {
    extends: FormField,
    data: function() {
        return {
            focus: false
        };
    },
    props: {
        value: {
            default: undefined
        },
        mask: {
            default: undefined
        },
        label: {
            default: false
        }
    },
    methods: {
        trigger(v) {
            this.$emit('input', v.target.value);
        }
    },
    created() {
        if (typeof this.value === 'undefined') {
            this.$emit('input', '');
        }
    }
};
</script>
