var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-4 px-7 -mx-4 flex items-center justify-between flex-wrap text-grey-darkest text-sm font-normal text-left"},[_c('div',{staticClass:"flex px-4",class:{'invisible': _vm.isOnePage}},[_c('a',{staticClass:"p-2 rounded-full inline-block cursor-pointer",class:{
                'bg-grey-light cursor-not-allowed': _vm.onFirstPage,
                'bg-grey-light hover:bg-gray-200': !_vm.onFirstPage,
                'w-10 h-10': !_vm.small, 'w-8 h-8': _vm.small
            },on:{"click":_vm.prevPage}},[_c('svg',{staticClass:"fill-current",class:{'text-grey-darker': !_vm.onFirstPage, 'text-grey-dark': _vm.onFirstPage},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z"}})])]),_vm._v(" "),_vm._l((_vm.pageRange),function(p){return _c('a',{key:p,staticClass:"p-2 ml-2 flex items-center justify-center leading-none rounded-full inline-block cursor-pointer bg-primary",class:{ 'text-white-4t': p !== _vm.page, 'text-white-8t': p === _vm.page, 'w-10 h-10': !_vm.small, 'w-8 h-8': _vm.small
            },on:{"click":function($event){return _vm.toPage(p)}}},[_vm._v(_vm._s(p))])}),_vm._v(" "),_c('a',{staticClass:"p-2 rounded-full inline-block ml-2 cursor-pointer",class:{
                'bg-grey-light cursor-not-allowed': _vm.onLastPage,
                'bg-grey-light hover:bg-gray-200': !_vm.onLastPage,
                'w-10 h-10': !_vm.small, 'w-8 h-8': _vm.small
            },on:{"click":_vm.nextPage}},[_c('svg',{staticClass:"fill-current",class:{'text-grey-darker': !_vm.onLastPage, 'text-grey-dark': _vm.onLastPage},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"}})])])],2),_vm._v(" "),(_vm.onepageable)?_c('div',{staticClass:"px-4"},[_c('a',{staticClass:"btn btn-primary",class:{'btn-sm': !_vm.small, 'btn-xs': _vm.small},attrs:{"href":"#"},domProps:{"innerHTML":_vm._s(_vm.onePageButtonText)},on:{"click":function($event){$event.preventDefault();return _vm.switchOnePageMode($event)}}})]):_vm._e(),_vm._v(" "),(_vm.range)?_c('div',{staticClass:"px-4 flex-grow flex items-center justify-end text-grey-dark"},[_vm._v("\n        Seite "+_vm._s(_vm.page)+" von "+_vm._s(_vm.pages)+"\n    ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }