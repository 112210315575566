<template>
    <div>
        <grid rows="1fr 1fr" dense>
            <div col="1" class="subtitle">Personendaten</div>
            <item col="1"><f-text v-model="data.firstname" label="Vorname"></f-text></item>
            <item col="1"><f-text v-model="data.lastname" label="Nachname"></f-text></item>
            <item col="1"><f-text v-model="data.address" label="Strasse"></f-text></item>
            <item col="1"><f-text v-model="data.nr" label="Nr"></f-text></item>
            <item col="1"><f-text v-model="data.zip" label="PLZ"></f-text></item>
            <item col="1"><f-text v-model="data.location" label="Ort"></f-text></item>
            <div col="2" class="subtitle">Kontaktdaten</div>
            <item col="2"><f-text v-model="data.phone" label="Tel (Festnetz)"></f-text></item>
            <item col="2"><f-text v-model="data.mobile" label="Tel (Mobil)"></f-text></item>
            <item col="2"><f-text v-model="data.email" label="E-Mail"></f-text></item>
            <item col="2"><f-text v-model="data.fax" label="Fax"></f-text></item>
            <item col="2"><f-text v-model="data.fax" label="Fax"></f-text></item>
            <item col="1"><f-select v-model="data.category_id" :options="categories" label="Kategorie"></f-select></item>
            <item col="1 / -1"><btn @click="$inertia.patch(data.links.update, data)" primary>Speichern</btn></item>
        </grid>

    </div>
</template>

<script>
import App from '../../layouts/App';

export default {
    layout: App,

    props: {
        data: {},
        categories: {}
    },

    methods: {
        triggerAdd() {
            this.$store.dispatch('address/update', this.values).then(ret => {
                this.$router.push({ name: 'address.index' });
            });
        }
    }
};
</script>
