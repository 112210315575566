<template>
    <div class="p-6">
        <div class="flex items-end">
            <div class="flex-grow">
                <f-select label="Immobilie auswählen" v-model="settings.property" :options="properties"></f-select>
            </div>
            <f-checkbox class="ml-2" v-model="settings.showAll" label="Alle Termine anzeigen" size="sm"></f-checkbox>
        </div>

        <!-- 
        <v-dialog v-model="adding" max-width="500px">
            <v-card>
                <v-card-title>
                    Termin hinzufügen
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-text-field type="datetime" name="from" v-model="add.from"></v-text-field>
                    <v-text-field type="datetime" name="to" v-model="add.to"></v-text-field>
                </v-card-text>
                <v-card-actions right>
                    <v-btn color="green darken-3" flat @click.stop="addEvent()">Hinzufügen</v-btn>
                    <v-btn color="red darken-3" flat @click.stop="adding=false">Abbrechen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editing" max-width="500px">
            <v-card>
                <v-card-title>
                    Termin bearbeiten
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-text-field type="datetime" name="from" v-model="edit.from"></v-text-field>
                    <v-text-field type="datetime" name="to" v-model="edit.to"></v-text-field>
                </v-card-text>
                <v-card-actions right>
                    <v-btn color="green darken-3" flat @click.stop="editEvent()">Bearbeiten</v-btn>
                    <v-btn color="red darken-3" flat @click.stop="editing=false">Abbrechen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleting" max-width="500px">
            <v-card>
                <v-card-title>
                    Termin löschen
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    Wollen Sie diesen Termin löschen?
                </v-card-text>
                <v-card-actions right>
                    <v-btn color="red darken-3" flat @click.stop="deleteEvent()">Löschen</v-btn>
                    <v-btn color="green darken-3" flat @click.stop="deleting=false">Abbrechen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="infoing" max-width="500px">
            <v-card v-if="info">
                <v-card-title>
                    Termin
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    Gebucht von {{ info.client.firstname }} {{ info.client.lastname }}<br>
                    <b>Adresse:</b> {{ info.client.address }}, {{ info.client.zip }} {{ info.client.city }}
                </v-card-text>
                <v-card-actions right>
                    <v-btn color="green darken-3" flat @click.stop="infoing=false">Schließen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        -->
        <calendar @eventClick="openDeleteDialog"
            @deleteEvent="displayDeleteDialog"
            @editEvent="displayEditDialog"
            @addEvent="displayAddDialog"
            @info="displayInfoDialog"
            v-model="events"
            :duration="settings.duration"
            deleteable
            editable
            infoable
            :createable="settings.property !== null"
        ></calendar>
    </div>
</template>

<style lang="less">

</style>

<script>
    import _ from 'lodash';
    import App from '../../layouts/App';
    import Calendar from './Calendar.vue'

    export default {

        layout: App,

        props: {
            properties: {},
            settings: {},
            durations: {}
        },

        components: { Calendar },

        data: function() {
            return {
                adding: false,
                add: {
                    from: null,
                    to: null
                },
                deleteData: null,
                editing: false,
                deleting: false,
                infoing: false,
                edit: {
                    property: null,
                    from: null,
                    id: null,
                    to: null
                },
                delete: {
                    id: null,
                    property: null
                },
                info: null
            };
        },
        // components: { Calendar },
        methods: {
            deleteEvent: function(event) {
                axios.delete('/api/property/'+this.$route.params.id+'/event/'+event.id).then((ret) => {
                    this.events = this.events.filter((e) => {
                        return event.id != e.id;
                    });

                    this.deleteData = null;
                });
            },
            openDeleteDialog: function(event) {
                this.$set(this, 'deleteData', event);
            },
            addEvent: function() {
                var vm = this;

                axios.post('/api/property/'+this.property.id+'/event', this.add).then((ret) => {
                    this.properties.findById(this.property.id).events.push(ret.data);

                    vm.adding = false;
                    vm.add = {
                        from: null,
                        to: null
                    };
                });
            },
            editEvent: function() {
                var vm = this;

                axios.patch(`/api/property/${this.edit.property}/event/${this.edit.id}`, this.edit).then((ret) => {
                    this.properties.findById(this.edit.property)
                        .events.updateModel(ret.data);

                    vm.editing = false;

                    vm.edit = {
                        property: null,
                        from: null,
                        to: null,
                        id: null
                    };
                });
            },
            deleteEvent: function() {
                var vm = this;

                axios.delete('/api/property/'+this.delete.property+'/event/'+this.delete.id).then((ret) => {
                    this.properties.findById(this.delete.property)
                        .events.destroyModel(vm.delete);

                    vm.deleting = false;
                });
            },
            displayAddDialog(from, to) {
                this.add.from = from;
                this.add.to = to;
                this.adding = true;
            },
            displayEditDialog(event) {
                this.edit.from = event.from;
                this.edit.to = event.to;
                this.edit.property = event.property_id;
                this.edit.id = event.id;
                this.editing = true;
            },
            displayDeleteDialog(event) {
                this.delete.id = event.id;
                this.delete.property = event.property_id;
                this.deleting = true;
            },
            displayInfoDialog(event) {
                this.$set(this, 'info', event);
                this.infoing = true;
            }
        },
        computed: {
            events() {
                return [];
            },
            visibleProperties() {
                return this.showAll
                    ? this.properties
                    : this.properties.filterByModel(this.property)
                ;
            },
            deleteDialogVisible: function() {
                return this.deleteData !== null;
            }
        }
    }
</script>
