<template>
    <div class="range-slider">
        <input
            class="range-slider__range"
            type="range"
            v-bind="attrs"
            @input="$emit('input', parseInt($event.target.value))"
            :value="value"
            :name="name"
        />
        <span class="range-slider__value" v-html="value"></span>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
            type: Number
        },
        min: {
            default: null
        },
        max: {
            default: null
        },
        step: {
            default: null
        },
        name: {
            default: '',
            type: String
        }
    },
    computed: {
        attrs() {
            var bind = {};
            if (this.min !== null) {
                bind.min = this.min;
            }
            if (this.max !== null) {
                bind.max = this.max;
            }
            if (this.step !== null) {
                bind.step = this.step;
            }

            return bind;
        }
    }
};
</script>
