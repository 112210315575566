export default {
    props: {
        sm: { type: Boolean },
        xs: { type: Boolean },
        md: { type: Boolean },
        lg: { type: Boolean },
        xl: { type: Boolean },
        tag: {
            default: 'div'
        },
        col: {
            default: 'auto'
        },
        row: {
            default: 'auto'
        }
    },
    data: function() {
        return {
            sizes: {
                xs: 'tw-w-1/5',
                sm: 'tw-w-1/4',
                md: 'tw-w-1/2',
                lg: 'tw-w-3/4',
                xl: 'tw-w-4/5'
            }
        };
    },
    computed: {
        size() {
            var size = Object.keys(this.sizes).find((size) => {
                return this.$props[size] === true;
            });

            return size ? this.sizes[size] : null;
        },
        classes() {
            var cls = '';

            if (this.size) {
                cls += this.size;
            }

            return cls || null;
        }
    },
    render(createElement) {
        var attrs = {};

        if (this.classes) {
            attrs.class = this.classes;
        }

        var style = `grid-column: ${this.col}; grid-row: ${this.row}`;

        return createElement(this.$props.tag, { attrs, style }, this.$slots.default);
    }
};
