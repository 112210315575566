<template>
    <div>
        <v-subheader class="pa-0">Google-Einstellungen</v-subheader>
        <v-btn :label="label" @click="action" :loading="loading" :color="color">{{ label }}</v-btn>
        <form ref="promptForm" action="/google/calendar/auth/prompt" target="_BLANK">
            <button type="submit" style="display: none;"></button>
        </form>

        <v-select :items="calendars"
            v-if="calendars !== null"
            item-value="id"
            item-text="title"
            label="Kalender"
            v-model="innerValue"
        >
        </v-select>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            isAuthed: null,
            calendars: null
        };
    },
    props: {
        value: {}
    },
    computed: {
        label() {
            switch(this.isAuthed) {
                case false: return 'Nicht verbunden';
                case true: return 'Verbunden';
                case null: return 'Prüfe';
                default: return this.isAuthed;
            }
        },
        innerValue: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit('input', v);
            }
        },
        color() {
            switch(this.isAuthed) {
                case false: return 'red';
                case true: return 'green';
                case null: return 'grey';
                default: return 'red';
            }
        },
        loading() {
            return this.isAuthed === null;
        },
    },
    methods: {
        action() {
            if (this.isAuthed === true) {this.disconnect();}
            if (this.isAuthed === false) {this.connect();}
        },
        connect() {
            this.$refs.promptForm.submit();
        }
    },
    created() {
        axios.get('/api/google/calendar/check').then((ret) => {
            this.isAuthed = ret.data.response === true;
        }).catch((error) => {
            this.isAuthed = false;
        });

        axios.get('/api/google/calendar/list').then((ret) => {
            if (!ret.data.error) {
                this.calendars = ret.data;
            }
        }).catch((error) => {});
    }
};
</script>
