<template>
    <div>
        <grid rows="1fr 1fr" dense>
            <div col="1" class="subtitle">Personendaten</div>
            <item col="1"><f-text v-model="values.firstname" label="Vorname"></f-text></item>
            <item col="1"><f-text v-model="values.lastname" label="Nachname"></f-text></item>
            <item col="1"><f-text v-model="values.address" label="Strasse"></f-text></item>
            <item col="1"><f-text v-model="values.nr" label="Nr"></f-text></item>
            <item col="1"><f-text v-model="values.zip" label="PLZ"></f-text></item>
            <item col="1"><f-text v-model="values.location" label="Ort"></f-text></item>
            <div col="2" class="subtitle">Kontaktdaten</div>
            <item col="2"><f-text v-model="values.phone" label="Tel (Festnetz)"></f-text></item>
            <item col="2"><f-text v-model="values.mobile" label="Tel (Mobil)"></f-text></item>
            <item col="2"><f-text v-model="values.email" label="E-Mail"></f-text></item>
            <item col="2"><f-text v-model="values.fax" label="Fax"></f-text></item>
            <item col="1"><f-select v-model="values.category_id" :options="categories" label="Kategorie"></f-select></item>
            <item col="1 / -1"><btn @click="$inertia.post(meta.links.store, values)" primary>Speichern</btn></item>
        </grid>

    </div>
</template>

<script>
import App from '../../layouts/App';

export default {

    layout: App,

    data: function() {
        return {
            values: {}
        };
    },

    props: {
        categories: {},
        meta: {}
    }

};
</script>
