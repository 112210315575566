<template>
    <form @submit.prevent="submit" class="relative">

        <div class="absolute mr-2 mt-2 right-0 top-0 z-2">
            <f-submit primary>Speichern</f-submit>
        </div>

        <grid columns="1fr 4fr">
            <item>
                <v-menu @input="scroll" v-model="currentMenu"></v-menu>
            </item>
            <item v-show="currentMenu == 'Objektart'">
                <grid class="form-grid">
                    <item>
                        <f-select v-model="value.property_category_id" :options="categories" label="Kategorie" required></f-select>
                    </item>
                    <item v-if="categoryRelation('kinds').length !== 0">
                        <f-select v-model="value.property_kind_id" :options="categoryRelation('kinds')" label="Art"></f-select>
                    </item>
                    <item v-if="categoryRelation('usages').length !== 0">
                        <f-select v-model="value.property_usage_id" :options="categoryRelation('usages')" label="Nutzung"></f-select>
                    </item>
                    <item>
                        <f-select v-model="value.marketing_kind_id" :options="marketingkinds" label="Vermarktungsart"></f-select>
                    </item>
                </grid>
            </item>
            <item v-show="currentMenu == 'Eigentümerdaten'">
                <eigentuemer v-model="value.eigentuemers"></eigentuemer>
            </item>
            <item v-show="currentMenu == 'Objektdaten'">
                <grid class="form-grid" columns="1fr 1fr" dense>
                    <item col="1 / 3"><div class="subtitle">Objektadresse</div></item>
                    <item col="1"><f-text v-model="value.address" label="Strasse"></f-text></item>
                    <item col="1"><f-text v-model="value.address_nr" label="Nr"></f-text></item>
                    <item col="1"><f-text v-model="value.zip" label="PLZ"></f-text></item>
                    <item col="1"><f-text v-model="value.location" label="Ort"></f-text></item>
                    <item col="1"><f-select v-model="value.region_id" :options="regions" label="Bundesland"></f-select></item>
                    <item col="1"><f-select v-model="value.country_id" :options="countries" label="Land"></f-select></item>
                    <item col="2"><f-text v-model="value.verfuegbar_ab" label="Verfügbar ab"></f-text></item>
                    <item col="2">
                        <f-switches v-model="value.show_address" :options="{d: 'Auf Portalen', p: 'im PDF (Druck)'}" label="Adresse freigeben"></f-switches>
                    </item>
                    <item col="2"><f-switch v-model="value.bewohnt" label="Vom Eigentümer bewohnt"></f-switch></item>
                    <item col="2"><f-switch v-model="value.vermietet" label="zurzeit vermietet"></f-switch></item>
                </grid>
            </item>
            <item v-show="currentMenu == 'Grundbuchdaten'">
                <grid class="form-grid">
                    <item><f-text v-model="value.amtsgericht" label="Amtsgericht"></f-text></item>
                    <item><f-text v-model="value.bezirk" label="Bezirk"></f-text></item>
                    <item><f-text v-model="value.grundbuchblatt" label="Grundbuchblatt"></f-text></item>
                    <item><f-text v-model="value.gemarkung" label="Gemarkung"></f-text></item>
                    <item><f-text v-model="value.flur" label="Flur"></f-text></item>
                    <item><f-text v-model="value.flurstueck" label="Flurstück"></f-text></item>
                    <item><f-text v-model="value.dienstbarkeiten" label="Dienstbarkeiten"></f-text></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Wichtige Dokumente'">
                Folgt …
            </item>

            <item v-show="currentMenu == 'Käuferdaten'">
                <kaeufer v-model="value.kaeufers"></kaeufer>
            </item>

            <item v-show="currentMenu == 'Grundstück'">
                <grid class="form-grid" columns="1fr 1fr" dense>
                    <item col="1"><f-decimal v-model="value.flgrundstueck" label="Fläche Grundstück" mask="9[9][9][9][9][9][9][9],99"></f-decimal></item>
                    <item col="1"><f-decimal v-model="value.flgarten" label="Fläche Garten" mask="9[9][9][9][9][9][9][9],99"></f-decimal></item>
                    <item col="1"><f-decimal v-model="value.flland" label="Fläche Land- & Forstwirtschaft" mask="9[9][9][9][9][9][9][9],99"></f-decimal></item>
                    <item col="1"><f-decimal v-model="value.flwald" label="Fläche Wald" mask="9[9][9][9][9][9][9][9],99"></f-decimal></item>
                    <item col="1"><f-decimal v-model="value.flsonstige" label="Fläche Sonstige" mask="9[9][9][9][9][9][9][9],99"></f-decimal></item>
                    <item col="1"><f-price v-model="value.bodenrichtwert" label="Bodenrichtwert Bauland (€ / m²)"></f-price></item>
                    <item col="2"><f-text v-model="value.altlasten" label="Altlasten"></f-text></item>
                    <item col="2"><f-text v-model="value.baulasten" label="Baulasten"></f-text></item>
                    <item col="2"><f-text v-model="value.grunddienstbarkeiten" label="Grunddienstbarkeiten"></f-text></item>
                    <item col="2"><f-select v-model="value.grzustand" :options="grundstueckZustand" label="Zustand" ></f-select></item>
                    <item col="2"><f-select v-model="value.erschliessung" :options="erschliessung" label="Erschließungsumfang" ></f-select></item>
                    <item col="2"><f-price v-model="value.erschliessungskosten" label="Erschließungskosten"></f-price></item>
                    <item col="1 / -1"><div class="subtitle">Widmung und Dichte</div></item>
                    <item col="1"><f-text v-model="value.widmung" label="Widmung"></f-text></item>
                    <item col="1"><f-text v-model="value.verbaubarkeit" label="Verbaubarkeit"></f-text></item>
                    <item col="1"><f-text v-model="value.bauklasse" label="Bauklasse"></f-text></item>
                    <item col="1"><f-text v-model="value.energietyp" label="Energietyp"></f-text></item>
                    <item col="2"><f-text v-model="value.dachform" label="Dachform"></f-text></item>
                    <item col="2"><f-text v-model="value.bauweise" label="Bauweise"></f-text></item>
                    <item col="2"><f-text v-model="value.ausbaustufe" label="Ausbaustufe"></f-text></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Objekt'">
                <grid class="form-grid" columns="1fr 1fr" dense>
                    <item><f-text v-model="value.baujahr" label="Baujahr"></f-text></item>
                    <item><f-text v-model="value.wohnflaeche" label="Wohnfläche"></f-text></item>
                    <item><f-text v-model="value.nutzflaeche" label="Nutzfläche"></f-text></item>
                    <item><f-text v-model="value.kellerflaeche" label="Kellerfläche"></f-text></item>
                    <item><f-text v-model="value.anzwohneinheiten" label="Anzahl Wohneinheiten"></f-text></item>
                    <item><f-text v-model="value.anzetagen" label="Anzahl Etagen"></f-text></item>
                    <item><f-text v-model="value.etage" label="Etage"></f-text></item>
                    <item><f-text v-model="value.anzstellpl" label="Anzahl Stellplätze"></f-text></item>
                    <item><f-select v-model="value.stellplatzart" :options="stellplatzart" label="Stellplatzart"></f-select></item>
                    <item><f-text v-model="value.anzzimmer" label="Anzahl Zimmer"></f-text></item>
                    <item><f-text v-model="value.anzschlafzimmer" label="Anzahl Schlafzimmer"></f-text></item>
                    <item><f-text v-model="value.anzbadezimmer" label="Anzahl Badezimmer"></f-text></item>
                    <item><f-text v-model="value.anzgaestewc" label="Anzahl Gäste WC"></f-text></item>
                    <item><f-text v-model="value.anzbalkone" label="Anzahl Balkone"></f-text></item>
                    <item><f-text v-model="value.anzterrasse" label="Anzahl Terrassen"></f-text></item>
                    <item><f-checkbox v-model="value.gartenbenutzung" label="Gartenbenutzung"></f-checkbox></item>
                    <item><f-text v-model="value.gartengroesse" label="Gartengröße"></f-text></item>
                    <item><f-checkbox v-model="value.gartenalleine" label="Sondernutzung Garten alleine"></f-checkbox></item>
                    <item><f-checkbox v-model="value.gemgarten" label="Gemeinschaftsgarten"></f-checkbox></item>
                    <item><f-checkbox v-model="value.denkmalgeschuetzt" label="Denkmalgeschützt"></f-checkbox></item>
                    <item><f-checkbox v-model="value.gewnutzung" label="Gewerbliche Nutzung"></f-checkbox></item>
                    <item><f-checkbox v-model="value.haustiere" label="Haustiere"></f-checkbox></item>
                    <item><f-checkbox v-model="value.einliegerbereich" label="Einliegerbereich"></f-checkbox></item>
                    <item><f-text v-model="value.fleinliegerbereich" label="Fläche Einliegerbereich"></f-text></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Sanierungen'">
                <p class="font-semibold">Diese Felder werden nicht exportiert.</p>
                <sanierungen v-model="value.sanierungen"></sanierungen>
            </item>

            <item v-show="currentMenu == 'Energieausweis'">
                <ea v-model="value"></ea>
            </item>

            <item v-show="currentMenu == 'Anlageobjekt'">
                <grid class="form-grid" columns="1fr" dense>
                    <item><f-text v-model="value.vermietungsflaeche_wohnen" label="Vermietungsfläche (Wohnfläche)"></f-text></item>
                    <item><f-text v-model="value.vermietungsflaeche_gewerbe" label="Vermietungsfläche (Gewerbefläche)"></f-text></item>
                    <item><f-text v-model="value.vermietungsflaeche_gesamt" label="Vermietungsfläche gesamt"></f-text></item>
                    <item><f-text v-model="value.anz_wohneinheiten" label="Anzahl Wohneinheiten"></f-text></item>
                    <item><f-text v-model="value.anz_gewerbeeinheiten" label="Anzahl Gewerbeeinheiten"></f-text></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Gewerbeobjekt'">
                <grid class="form-grid" columns="1fr" dense>
                    <item><f-text v-model="value.anz_gewerbeeinheiten" label="Anzahl Gewerbeeinheiten"></f-text></item>
                    <item><f-text v-model="value.prod_lagerflaeche" label="Produktions und/oder Lagerfläche"></f-text></item>
                    <item><f-text v-model="value.prod_lagerflaeche_teilbar_ab" label="Teilbar ab"></f-text></item>
                    <item><f-text v-model="value.bueroflaechen" label="Büroflächen"></f-text></item>
                    <item><f-text v-model="value.verkaufsflaeche_laden_geschaeft" label="Verkaufsfläche im Laden / Geschäft"></f-text></item>
                    <item><f-text v-model="value.gewerbe_gesamtflaeche" label="Gesamtfläche"></f-text></item>
                    <item><f-text v-model="value.fensterfront" label="Fensterfront"></f-text></item>
                    <item><div class="subtitle">Gastrogewerbe</div></item>
                    <item><f-text v-model="value.gastronomieflaeche" label="Gastronomieflaeche"></f-text></item>
                    <item><f-text v-model="value.anz_bewirtungsplaetze" label="Anzahl Bewirtungsplätze"></f-text></item>
                    <item><f-text v-model="value.anz_betten" label="Anzahl Betten"></f-text></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Betriebskosten und Einnahmen'">
                <item><div class="subtitle">Wohnen</div></item>
                <item><f-price v-model="value.hausgeld" label="Hausgeld"></f-price></item>
                <item><f-price v-model="value.nebenkosten" label="Nebenkosten"></f-price></item>
                <item><f-price v-model="value.heizkosten_mtl" label="Heizkosten monatlich"></f-price></item>
                <item><f-price v-model="value.ruecklagen_mtl" label="Rücklagenanteil monatlich"></f-price></item>
                <item><f-price v-model="value.ruecklagen_ges" label="Summe der anteiligen Rücklagen"></f-price></item>
                <item>
                    <div class="flex">
                        <div class="flex-grow mr-2">
                            <f-price v-model="value.erbpacht" label="Erbpacht"></f-price>
                        </div>
                        <div>
                            <f-select v-model="value.erbpacht_unit" :options="[{id: 'jahr', title: 'pro Jahr'}, {id: 'monat', title: 'pro Monat'}]" empty-label></f-select>
                        </div>
                    </div>
                </item>
                <item><f-text v-model="value.erbpacht_rest" label="Restdauer der Erbpacht"></f-text></item>
                <item><div class="subtitle">Grundstück</div></item>
                <item><f-price v-model="value.pacht" label="Pacht"></f-price></item>
            </item>

            <item v-show="currentMenu == 'Status'">
                <grid class="form-grid" fullsize>
                    <item><span class="block mb-6 label-font label-placeholder">Systemnummer: {{value.id}}</span></item>
                    <item><f-text v-model="value.objekt_id" label="Objektnummer intern"></f-text></item>
                    <item><f-text v-model="value.quelle_name" label="Name der Quelle"></f-text></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Beschreibung'">
                <description v-model="value"></description>
            </item>

            <item v-show="currentMenu == 'Preise & Provision'">
                <grid class="form-grid">
                    <item><f-price v-model="value.price" label="Objektkaufpreis"></f-price></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Wohnen'">
                <grid class="form-grid" columns="1fr 1fr">
                    <item col="1 / 3"><div class="subtitle">Ausstattung</div></item>
                    <item><f-select v-model="value.ausstattung_wohnen" :options="ausstattung" label="Ausstattung" ></f-select></item>
                    <item><f-select v-model="value.zustand" :options="zustand" label="Zustand" ></f-select></item>
                    <item><f-select v-model="value.ausrichtung_garten" :options="ausrichtung_garten" label="Ausrichtung Garten / Balkon"></f-select></item>
                    <item><f-checkbox v-model="value.moebliert" label="Möbliert"></f-checkbox></item>
                    <item col="1 / 3"><div class="subtitle">Fenster</div></item>
                    <item><f-select v-model="value.fenstermaterial" :options="fenstermaterial" label="Fenstermaterial" ></f-select></item>
                    <item><f-select v-model="value.fensterverglasung" :options="fensterverglasung" label="Fensterverglasung" ></f-select></item>
                    <item><f-checkbox v-model="value.schallschutz" label="Schallschutz"></f-checkbox></item>
                    <item><f-checkbox v-model="value.rollaeden" label="Rolläden"></f-checkbox></item>
                    <item><f-checkbox v-model="value.elektrisch" label="Elektrisch"></f-checkbox></item>
                    <item col="1 / 3"><div class="subtitle">Heizung</div></item>
                    <item><f-select v-model="value.heizungsart" :options="heizungsart" label="Heizungsart"></f-select></item>
                    <item><f-select v-model="value.befeuerung" :options="befeuerung" label="Befeuerung"></f-select></item>
                    <item><f-checkbox v-model="value.klimaanlage" label="Klimaanlage"></f-checkbox></item>
                    <item><f-text v-model="value.klimaanlage_nennleistung" label="Nennleistung Klimaanlage (kWp)"></f-text></item>
                    <item><f-checkbox v-model="value.kamin" label="Kamin"></f-checkbox></item>
                    <item col="1 / 3"><div class="subtitle">Boden</div></item>
                    <item><f-select v-model="value.bodenbelag" :options="bodenbelag" label="Bodenbelag"></f-select></item>
                    <item col="1 / 3"><div class="subtitle">Badezimmer</div></item>
                    <item><f-checkbox v-model="value.dusche" label="Dusche"></f-checkbox></item>
                    <item><f-checkbox v-model="value.badewanne" label="Badewanne"></f-checkbox></item>
                    <item><f-checkbox v-model="value.bidet" label="Bidet"></f-checkbox></item>
                    <item><f-checkbox v-model="value.urinal" label="Urinal"></f-checkbox></item>
                    <item><f-checkbox v-model="value.fenster" label="Fenster"></f-checkbox></item>
                    <item><f-checkbox v-model="value.gaestewc" label="Gäste-WC"></f-checkbox></item>
                    <item col="1 / 3"><div class="subtitle">Küche</div></item>
                    <item><f-checkbox v-model="value.einbaukueche" label="Einbauküche"></f-checkbox></item>
                    <item col="1 / 3"><div class="subtitle">Keller</div></item>
                    <item><f-checkbox v-model="value.kellerraum" label="Kellerraum"></f-checkbox></item>
                    <item><f-checkbox v-model="value.fahrradkeller" label="Fahrradkeller"></f-checkbox></item>
                    <item><f-checkbox v-model="value.waschkueche" label="Waschküche"></f-checkbox></item>
                    <item><f-checkbox v-model="value.hobbykeller" label="Hobbykeller"></f-checkbox></item>
                    <item><f-checkbox v-model="value.sauna" label="Sauna"></f-checkbox></item>
                    <item><f-checkbox v-model="value.technikkeller" label="Technikkeller"></f-checkbox></item>
                    <item col="1 / 3"><div class="subtitle">Strom</div></item>
                    <item><f-text v-model="value.stromanschluss" label="Stromanschluss (KW)"></f-text></item>
                    <item><f-text v-model="value.nennleistung" label="Nennleistung (kWo)"></f-text></item>
                    <item><f-checkbox v-model="value.photovoltaik" label="Photovoltaik"></f-checkbox></item>
                    <item><f-checkbox v-model="value.solar" label="Solar / Solarthermie"></f-checkbox></item>
                    <item col="1 / 3"><div class="subtitle">Kommunikation</div></item>
                    <item><f-checkbox v-model="value.kabelanschluss" label="Kabelanschluss"></f-checkbox></item>
                    <item><f-checkbox v-model="value.sat" label="SAT-Anlage"></f-checkbox></item>
                    <item><f-select v-model="value.internet" :options="internet" label="Internet"></f-select></item>
                    <item><f-text v-model="value.internetgeschwindigkeit" label="Internetgeschwindigkeit (mbit)"></f-text></item>
                    <item col="1 / 3"><div class="subtitle">Service</div></item>
                    <item><f-checkbox v-model="value.hausmeister" label="Hausmeister"></f-checkbox></item>
                    <item><f-checkbox v-model="value.gartenpflege" label="Gartenpflege"></f-checkbox></item>
                    <item><f-checkbox v-model="value.winterdienst" label="Winterdienst"></f-checkbox></item>
                    <item><f-checkbox v-model="value.treppenreinigung" label="Treppenreinigung"></f-checkbox></item>
                    <item col="1 / 3"><div class="subtitle">Dach</div></item>
                    <item><f-select v-model="value.dachart" :options="dachart" label="Dachart"></f-select></item>
                    <item col="1 / 3"><div class="subtitle">Fertighaus</div></item>
                    <item><f-select v-model="value.ausbaustufe" :options="ausbaustufe" label="Ausbaustufe"></f-select></item>
                    <item><f-select v-model="value.bauart" :options="bauart" label="Bauart"></f-select></item>
                    <item><f-select v-model="value.energietyp" :options="energietyp" label="Energietyp"></f-select></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Gastro'">
                <grid class="form-grid">
                    <item><f-checkbox v-model="value.aussenterrasse" label="Außenterrasse"></f-checkbox></item>
                    <item><f-checkbox v-model="value.brauereibindung" label="Brauereibindung"></f-checkbox></item>
                    <item><f-checkbox v-model="value.sporteinrichtungen" label="Sporteinrichtungen"></f-checkbox></item>
                    <item><f-checkbox v-model="value.wellness" label="Wellness"></f-checkbox></item>
                    <item><f-text v-model="value.anz_bewirtungsplaetze" label="Anzahl Bewirtungsplätze"></f-text></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Gewerbe'">
                <grid class="form-grid">
                    <item><f-checkbox v-model="value.dv_verkabelung" label="DV Verkabelung"></f-checkbox></item>
                    <item><f-checkbox v-model="value.kran" label="Kran"></f-checkbox></item>
                    <item><f-text v-model="value.anz_rampen" label="Anzahl Rampen"></f-text></item>
                    <item><f-checkbox v-model="value.hebebuehne" label="Hebebühne"></f-checkbox></item>
                    <item><f-text v-model="value.hallenhoehe" label="Hallenhöhe"></f-text></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Extras'">
                <grid class="form-grid">
                    <item><f-checkbox v-model="value.wintergarten" label="Wintergarten"></f-checkbox></item>
                    <item><f-checkbox v-model="value.swimmingpool" label="Swimmingpool"></f-checkbox></item>
                    <item><f-checkbox v-model="value.gartennutzung" label="Gartennutzung"></f-checkbox></item>
                    <item><f-checkbox v-model="value.alarmanlage" label="Alarmanlage"></f-checkbox></item>
                    <item><f-checkbox v-model="value.aufzug" label="Aufzug"></f-checkbox></item>
                    <item><f-checkbox v-model="value.barrierefrei" label="Barrierefrei"></f-checkbox></item>
                    <item><f-checkbox v-model="value.rollstuhlgerecht" label="Rollstuhlgerecht"></f-checkbox></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Fotos'">
                <photopea v-model="value.images"></photopea>
            </item>

            <item v-show="currentMenu == 'Social Media'">
                <grid class="form-grid">
                    <item><f-text v-model="value.virtuelle_besichtigung_link" label="Link zur virtuellen Besichtigung"></f-text></item>
                </grid>
            </item>

            <item v-show="currentMenu == 'Portale'" v-if="updating">
                <portale :portals="portals" :id="value.id"></portale>
            </item>

            <item>
            <f-submit primary>Speichern</f-submit>
            </item>
        </grid>
    </form>

</template>

<script>
import vMenu from './vMenu';
import Sanierungen from './Sanierungen';
import Ea from './Ea';
import Eigentuemer from './Eigentuemer';
import Kaeufer from './Kaeufer';
import Photopea from './Photopea';
import Portale from './Portale';
import Description from './Description';

export default {
    data: function() {
        return {
            values: {},
            currentMenu: 'Objektart',
            ausstattung: ['Luxus', 'gehoben', 'normal', 'einfach'],
            zustand: ['Neubau', 'projektiert', 'saniert', 'modernisiert', 'gepflegt', 'renovierungsbedürftig', 'sanierungsbedürftig'],
            ausrichtung_garten: ['keine', 'N', 'NO', 'O', 'SO', 'S', 'SW', 'N', 'NW'],
            fenstermaterial: ['Holz', 'Kunstoff', 'Alumimium'],
            fensterverglasung: ['einfach', 'doppelt', 'dreifach', 'doppelt-isoliert', 'dreifach-isoliert'],
            befeuerung: ['keine', 'Erdwärme', 'Fernwärme', 'Strom', 'Gas', 'Öl', 'Kohle', 'Infrarot'],
            heizungsart: ['Zentralheizung', 'Etagenheizung', 'Fernwärme', 'Ofenheizung', 'Fußbodenheizung', 'keine'],
            bodenbelag: ['Dielen', 'Parkett', 'Fliesen', 'Steinboden', 'Granit', 'Laminat', 'Teppich', 'Vinyl', 'Linoleum', 'Kork'],
            internet: ['DSL', 'Glasfaser'],
            dachart: ['Flachdach', 'Krüppelwalmdach', 'Pultdach', 'Mansarddach', 'Pyramidendach', 'Walmdach', 'Satteldach'],
            ausbaustufe: ['Ausbauhaus', 'Bausatzhaus', 'Schlüsselfertig mit Bodenplatte', 'Schlüsselfertig ohne Bodenplatte', 'Schlüsselfertig mit Keller'],
            bauart: ['Massivbau', 'Holz / Fachwerk', 'Fertigteile'],
            energietyp: ['KfW 40', 'KfW 55', 'KfW 60', 'KfW 70', 'Niedrig-Energiehaus', 'Passivhaus'],
            grundstueckZustand: [
                'bebaut', 'projektiert', 'bebaubar nach §34 BauGB', 'bebaubar nach B-Plan', 'Bauvoranfrage gestellt', 'nicht bebaubar'
            ],
            freigabe: [
                { id: 'p', title: 'Auf Portalen' },
                { id: 'd', title: 'im PDF (Druck)' },
                { id: null, title: 'gar nicht' }
            ],
            erschliessung: [
                'keine', 'Gas', 'Telefon, DSL, Glasfaser', 'Strom', 'Wasser / Abwasser', 'Straße'
            ],
            stellplatzart: [
                'Garage', 'Carport', 'Doppelgarage', 'Stellplatz im Freien', 'Tiefgarage', 'kein'
            ]
        };
    },
    props: {
        updating: {
            type: Boolean,
            default: false
        },
        value: {},
        categories: {},
        marketingkinds: {},
        countries: {},
        regions: {},
        portals: {}
    },
    components: { Kaeufer, vMenu, Eigentuemer, Sanierungen, Ea, Photopea, Portale, Description },
    methods: {
        scroll() {
            this.$scrollTo(document.getElementsByTagName('body')[0], 200);
        },
        categoryRelation(r) {
            console.log(r);
            if (!this.value.property_category_id) { return []; }
            return this.categories.find((i) => i.id === this.value.property_category_id)[r];
        },
        submit() {
            this.$emit('submit');
        }
    }
};

</script>

