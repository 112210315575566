<template>
    <div>
        <v-table :headers="[
            {text: 'Titel', value: 'title', align: 'left'},
            {text: 'Typ', value: 'type_id', align: 'left'}
            ]"
            :items="portals"
            actions
        >
            <template slot="Titel" slot-scope="{ item }">
                {{item.title}}
            </template>

            <template slot="Typ" slot-scope="{ item }">
                {{item.type.title}}
            </template>

            <template slot="actions" slot-scope="{ item }">
                <goto v-for="link, index in item.actionLinks" href="#" :key="index" @click.prevent="$inertia.post(link.href, { property_id: id })" icon="pencil" class="btn-sm btn btn-primary ml-1 text-white">{{link.label}}</goto>
            </template>
        </v-table>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        portals: {}
    }
};
</script>
