import { kebabCase } from 'lodash';
import merge from 'merge';

const requireComponent = require.context(
    './types', false, /^\.\/[^_].*\.vue$/
);

var c = {};

requireComponent.keys().forEach((file) => {
    let componentName = file.substr(2).replace('.vue', '');

    c[componentName] = requireComponent(file).default;
});

export default {
    components: c,

    computed: {
        component() {
            var type = this.value.type_id; 

            if (type === null || type === undefined) { return null; }
            
            var name = this.types.find(t => t.id == type).title;

            return name;
        }
    }
};
