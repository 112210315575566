<template>
    <div class="p-6">
        <calendar v-model="timer.events" @deleteevent="deleteEvent" @editevent="editEvent" @addevent="addEventModal" createable editable deleteable></calendar>
    </div>
</template>

<script>
import App from '../../../layouts/App';
import Calendar from '../Calendar';

export default {
    layout: App,
    props: {
        events: {},
        timer: {}
    },
    components: { Calendar },
    methods: {
        addEventModal(event) {
            this.$inertia.post(this.timer.links.events.store, event);
        },
        editEvent(event) {
            this.$inertia.patch(event.links.update, { starts_at: event.starts_at, ends_at: event.ends_at });
        },
        deleteEvent(event) {
            this.$inertia.delete(event.links.destroy);
        },

    }
};
</script>

<style>
.trigger-event-hover .on-event-hover {
    display: none;
}
.trigger-event-hover:hover .on-event-hover {
    display: flex;
}
</style>

