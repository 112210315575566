<template>
    <div class="relative" v-if="entries.length">
        <slot name="activator" :open="openIt" class="cursor-pointer"></slot>
        <transition name="bounce">
            <div class="absolute bg-white py-3 rounded-sm shadow-hard w-48 z-20 mt-2" v-if="opened"
                :class="pinH">
                <goto v-for="(entry, index) in entries" :href="entry.href"
                    class="text-gray-700 w-full hover:bg-gray-300 active:bg-grey-light px-5 py-3"
                    :icon="entry.icon"
                    :key="index"
                >
                    {{entry.title}}
                </goto>
            </div>
        </transition>
    </div>
</template>

<script>
export default {

    props: {
        name: {
            required: true,
            type: String
        },
        pin: {
            default: 'l'
        }
    },

    computed: {
        entries() {
            return this.$store.getters['nav/links'](this.name);
        },

        opened() {
            return this.$store.getters['nav/opened'](this.name);
        },

        pinH() {
            return { l: 'left', r: 'right'}[this.pin]+'-0';
        }
    },

    methods: {
        openIt() {
            this.$store.commit('nav/setState', { key: this.name, status: !this.opened });
        }
    }
};
</script>
