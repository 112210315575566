<template>
    <div>
        <goto :href="value.meta.links.create" class="btn btn-primary">Hinzufügen</goto>

        <v-table :headers="[
            {text: 'Titel', value: 'title', align: 'left'},
            {text: 'Typ', value: 'type_id', align: 'left'}
            ]"
            :items="value.data"
            actions
        >
            <template slot="Titel" slot-scope="{ item }">
                {{item.title}}
            </template>

            <template slot="Typ" slot-scope="{ item }">
                {{item.type.title}}
            </template>

            <template slot="actions" slot-scope="{ item, index }">
                <goto :href="item.links.edit" icon="pencil" class="btn btn-primary ml-1 text-white"></goto>
                <goto @click="$inertia.delete(item.links.destroy)" icon="trash" class="btn btn-danger ml-1 text-white"></goto>
            </template>
        </v-table>
    </div>
</template>

<script>
export default {
    props: {
        value: {}
    }
};
</script>
