<template>
    <div>
        <label :for="unique" class="field-wrapper focused">
            <span class="label-font label-placeholder" v-if="label">{{ label }}</span>

            <div class="-mx-1 flex flex-wrap">
                <div class="px-1 w-1/4" v-for="(upload, index) in images" :key="'upload'+index">
                    <div class="relative overlay-wrapper">
                        <img :src="upload.src">
                        <slot name="overlay" :upload="upload" cls="absolute w-full h-full overlay top-0 pin-l">
                        </slot>
                    </div>
                    <div class="text-sm">{{ upload.filename }}</div>
                    <div class="text-sm">{{ size(upload.size) }}</div>
                </div>
            </div>

            <a href="#" class="btn btn-primary text-white btn-sm self-start"
                @click="$refs.uploader.click()"
            >Datei auswählen</a>
        </label>

        <input ref="uploader" @change="pushToQueue($event.target.files)" :multiple="multiple" type="file" :id="unique" />
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            queue: []
        };
    },
    props: {
        multiple: {
            default: false,
            type: Boolean
        },
        label: {
            default: ''
        },
        value: {
            default: function() {
                return null;
            }
        }
    },
    methods: {
        pushToQueue(files) {
            for (var i = 0; i < files.length; i++) {
                this.upload(files[i]);
            }
        },
        size(s) {
            return (s / 100) + 'KB';
        },
        upload(file) {
            var upload = { filename: file.name, size: file.size, src: null };

            var reader = new FileReader();

            reader.onload = (e) => {
                upload.src = e.target.result;

                if (this.multiple) {
                    var queueIndex = this.queue.push(upload) - 1;
                } else {
                    this.queue = upload;
                }

                var xhr = new FormData();
                xhr.append('file', file);

                axios.post('/fileupload', xhr, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((ret) => {
                    upload = { ...upload, ...ret.data };

                    var v;

                    if (this.multiple) {
                        v = this.value;
                        v.push(upload);

                        this.queue.splice(queueIndex, 1);
                    } else {
                        v = upload;
                        this.queue = null;
                    }

                    this.$emit('input', v);
                });
            };

            reader.readAsDataURL(file);
        }
    },
    computed: {
        images() {
            if (this.multiple) {
                return [ ...this.value, ...this.queue ];
            }

            if (this.queue !== null) {
                return [ this.queue ];
            }

            return (this.value !== null) ? [ this.value ] : [];
        }
    },
    created() {
        if (this.value) { return; }
        if (this.multiple) {
            this.$emit('input', []);
        } else {
            this.$emit('input', null);
        }
    }
};
</script>

<style scoped>
    input[type="file"] {
        display: none;
    }

    img {
        max-width: 100%;
        margin: 0 auto;
        display: block;
        margin-bottom: 10px;
    }
    .overlay-wrapper .overlay {
        visibility: hidden;
    }

    .overlay-wrapper:hover .overlay {
        visibility: visible;
    }
</style>
