<template>
    <div class="absolute top-0 right-0 -mt-8 px-3">
        <slot></slot>
    </div>
</template>

<script>
export default {

};
</script>

