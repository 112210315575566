<template>
    <button type="submit" class="inline-block btn shadow-hard" :class="classes">
        <slot></slot>
    </button>
</template>

<script>
import Colors from './_colors.mixin.js';

export default {
    mixins: [Colors('bg')]
};
</script>
