var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('goto',{staticClass:"btn btn-primary",attrs:{"href":_vm.value.meta.links.create}},[_vm._v("Hinzufügen")]),_vm._v(" "),_c('v-table',{attrs:{"headers":[
        {text: 'Titel', value: 'title', align: 'left'},
        {text: 'Typ', value: 'type_id', align: 'left'}
        ],"items":_vm.value.data,"actions":""},scopedSlots:_vm._u([{key:"Titel",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.title)+"\n        ")]}},{key:"Typ",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.type.title)+"\n        ")]}},{key:"actions",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('goto',{staticClass:"btn btn-primary ml-1 text-white",attrs:{"href":item.links.edit,"icon":"pencil"}}),_vm._v(" "),_c('goto',{staticClass:"btn btn-danger ml-1 text-white",attrs:{"icon":"trash"},on:{"click":function($event){return _vm.$inertia.delete(item.links.destroy)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }