export default {
    main: {
        open: false,
        links: [
            { icon: 'home', href: '/', title: 'Dashboard' },
            { icon: 'cubes', href: 'https://virtual.next-immobilien.de', title: 'Virtuolo' },
            { icon: 'home', href: '/elly', title: 'Exposeerstellung' },
            { icon: 'home', href: '/inquiry', title: 'Kundendatenbank' },
            { icon: 'calendar', href: '/timer', title: 'Terminoto' },
            { icon: 'envelope-o', href: '/email', title: 'E-Mail' },
            { icon: 'address-book', href: '/address', title: 'Adressbuch' },
            { icon: 'home', href: '/aquise', title: 'Carla Akquise' },
            { icon: 'money', href: '/finance', title: 'Finn Finance' },
            { icon: 'home', href: '/olli', title: 'Olli Offer' },
            { icon: 'calendar', href: '/calendar', title: 'Kalender' },
            { icon: 'shopping-cart', href: '/sales', title: 'Sally Sales' },
            { icon: 'comments', href: '/bewertung', title: 'Bewertung' },
            { icon: 'phone', href: '/phone', title: 'Phone' },
            { icon: 'book', href: '/book', title: 'Maklerbuch' },
            { icon: 'money', href: '/banking', title: 'Abrechnung' },
            { icon: 'cog', href: '/setting/1/edit', title: 'Konfiguration', right: 'setting.manage' },
            { icon: 'sign-out', href: '/logout', title: 'Abmelden' }
        ]
    },
    profileToolbar: {
        open: false,
        links: [
            { icon: 'user', href: '/profile', title: 'Meine Daten' }
        ]
    },
    adminToolbar: {
        open: false,
        links: [
            { icon: 'key', href: '/setting/1/edit', title: 'Globale Einstellungen', right: 'setting.manage' },
            { icon: 'user', href: '/user', title: 'Benutzer' }
        ]
    }
};
