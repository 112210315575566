<template>
    <div class="flex items-center justify-between p-6">
        <div>
            <p class="text-sm text-gray-700 leading-5">
                Zeige
                <span class="font-medium" v-text="value.from"></span>
                -
                <span class="font-medium" v-text="value.to"></span>
                von
                <span class="font-medium" v-text="value.total"></span>
                Einträgen
            </p>
        </div>

        <div>
            <span class="relative z-0 inline-flex shadow-sm">

                <a href="#" @click.prevent="$emit('goto', value.current_page-1)" v-if="value.current_page != 1" class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md leading-5 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="&amp;laquo; Zurück" rel="prev">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                </a>

                <span aria-disabled="true" aria-label="&amp;laquo; Zurück" v-else>
                    <span class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-default rounded-l-md leading-5" aria-hidden="true">
                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </span>
                </span>

                <div v-for="page in pages">
                    <span v-if="page.current" class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-default leading-5" v-text="page.page"></span>
                    <a href="#" @click.prevent="$emit('goto', page.page)" v-else class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150" v-text="page.page"></a>
                </div>

                <a href="#" @click.prevent="$emit('goto', value.current_page + 1)" v-if="value.current_page != value.last_page" rel="next" class="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md leading-5 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Weiter &amp;raquo;">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                </a>

                <span v-else aria-disabled="true" aria-label="Weiter &amp;raquo;">
                    <span class="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-default rounded-r-md leading-5" aria-hidden="true">
                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                        </svg>
                    </span>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
export default {

    data: function() {
        return {
            listPages: 5
        };
    },
    
    props: {
        value: {
            required: true
        }
    },

    computed: {
        pages() {
            var minPageIndex = Math.min(this.value.last_page - this.listPages, Math.max(0, this.value.current_page - 1 - Math.floor(this.listPages / 2)));
            return [ ...Array(this.value.last_page).keys() ].map(key => {
                return { page: key+1, current: this.value.current_page == key+1 };
            }).slice(minPageIndex, minPageIndex + this.listPages);
        }
    }

};
</script>
