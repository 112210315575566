<template>
    <grid fullsize>
        <item v-if="opened !== false">
            <iframe src="https://www.photopea.com/"></iframe>
        </item>
        <item>
            <file-upload :value="value" @input="addFile" multiple>
                <div slot="overlay" slot-scope="{ upload, cls }" :class="'flex flex-col items-center justify-center '+cls">
                    <a href="#" @click="edit(upload)" class="block btn btn-primary text-white btn-sm"><span class="fa fa-pencil"></span></a>
                </div>
            </file-upload>
        </item>
    </grid>
</template>

<script>
export default {
    props: {
        value: {}
    },
    data: function() {
        return {
            opened: false
        };
    },
    methods: {
        newImage() {
            this.opened = null;
        },
        addFile(v) {
            this.$emit('input', v);
        },
        edit(upload) {
            var settings = {
                files: [ upload.editUrl ]
            };

            window.open('https://www.photopea.com/#' + encodeURI(JSON.stringify(settings)));
        }
    }
};
</script>
