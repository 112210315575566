<template>
    <grid fullsize>
        <item>
            <f-checkbox label="PLZ aktivieren" v-model="zipFilter"></f-checkbox>
            <f-text v-if="value.zip_import !== null" label="PLZ-Stellen" v-model="value.zip_import"></f-text>
        </item>

        <!-- <v-slider label="Abweichung vom Preis" :min="0" :max="100"
            v-model="value.price_import"
            ></v-slider> -->

        <item>
        <f-checkbox label="Tage aktivieren" v-model="dayFilter"></f-checkbox>
        <f-text  v-if="value.day_import !== null" label="Anzahl Tage nach Veröffentlichung" v-model="value.day_import"></f-text>
        </item>

        <f-text label="Text für E-Mail-Benachrichtigung" v-model="value.offer_text"></f-text>
    </grid>
</template>

<script>
export default {
    props: {
        value: {}
    },
    computed: {
        zipFilter: {
            set(v) {
                this.value.zip_import = v ? '' : null;
            },
            get() {
                return this.value.zip_import !== null;
            }
        },

        dayFilter: {
            set(v) {
                this.value.day_import = v ? '' : null;
            },
            get() {
                return this.value.day_import !== null;
            }
        }
    }
};
</script>
