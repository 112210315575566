var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative p-6"},[_c('toolbar',[_c('goto',{staticClass:"text-white",attrs:{"href":_vm.data.meta.links.create,"icon":"plus"}},[_vm._v("Adresse anlegen")])],1),_vm._v(" "),_c('v-table',{attrs:{"headers":[
        {text: 'Name', value: 'fullname', align: 'left'},
        {text: 'E-Mail', value: 'email', align: 'left'},
        {text: 'Adresse', value: 'address', align: 'left'},
        {text: 'Kategorie', value: 'category_id', align: 'left'} ],"items":_vm.data.data,"actions":""},scopedSlots:_vm._u([{key:"Name",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+"\n        ")]}},{key:"E-Mail",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.email)+"\n        ")]}},{key:"Adresse",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.address)+" "+_vm._s(item.nr)+", "+_vm._s(item.zip)+" "+_vm._s(item.location)+"\n        ")]}},{key:"Kategorie",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.category.title)+"\n        ")]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [_c('goto',{staticClass:"btn btn-primary ml-1 text-white",attrs:{"href":item.links.edit,"icon":"pencil"}}),_vm._v(" "),_c('goto',{staticClass:"btn btn-danger ml-1 text-white",attrs:{"icon":"trash"},on:{"click":function($event){return _vm.$inertia.delete(item.links.destroy)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }