<template>
    <button @click.prevent="clicked" type="button" class="inline-block btn shadow-hard" :class="classes">
        <slot></slot>
    </button>
</template>

<script>
import Colors from './_colors.mixin.js';

export default {
    mixins: [Colors('bg')],
    methods: {
        clicked() {
            this.$emit('click');
        }
    }
};
</script>
