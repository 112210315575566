<template>
    <label :for="unique" class="field-wrapper focused">
        <span class="label-font label-placeholder" v-if="label">{{ label }}</span>
        <input type="text" :id="unique" v-model="decimalValue"
            class="form-control form-control-outline" :data-cy="dataCy"
            v-mask="'9[9][9][9][9][9][9][9],99'"
        >
    </label>
</template>

<script>
import FormField from './FormField.js';

export default {
    extends: FormField,
    data: function() {
        return {
            focus: false
        };
    },
    props: {
        value: {
            default: undefined
        },
        mask: {
            default: undefined
        },
        label: {
            default: false
        }
    },
    computed: {
        decimalValue: {
            set(v) {
                if (/^[0-9]+,[0-9]{2}$/.test(v)) {
                    this.$emit('input', parseInt(v.replace(',', '')));
                }
            },
            get() {
                if (typeof this.value === 'number') {
                    throw new Error(`Decimal field ${this.label} requires integer value`);
                }

                return this.value.toString().replace(/([0-9]{2})$/, ',$1');
            }
        }
    },
    methods: {
    },
    created() {
        if (typeof this.value === 'undefined') {
            this.$emit('input', '');
        }
    }
};
</script>
