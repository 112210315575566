<template>
    <div class="tab w-full flex flex-col" v-show="status">
        <slot></slot>
    </div>
</template>

<script>
import { paramCase } from 'change-case';

export default {
    data: function() {
        return {
            status: false
        };
    },
    props: {
        label: {
            type: String,
            required: true
        }
    },
    computed: {
        labelId() {
            return paramCase(this.label);
        }
    },
    created() {
        this.$on('setVisibility', (status) => {
            this.status = status;
        });
    }
};
</script>
