export default {
    props: {
        dataCy: {
            type: String,
            required: false
        },
        fieldClass: {
            type: String,
            default: ''
        }
    }
};
