var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-table',{attrs:{"headers":[
        {text: 'Titel', value: 'title', align: 'left'},
        {text: 'Typ', value: 'type_id', align: 'left'}
        ],"items":_vm.portals,"actions":""},scopedSlots:_vm._u([{key:"Titel",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.title)+"\n        ")]}},{key:"Typ",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.type.title)+"\n        ")]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return _vm._l((item.actionLinks),function(link,index){return _c('goto',{key:index,staticClass:"btn-sm btn btn-primary ml-1 text-white",attrs:{"href":"#","icon":"pencil"},on:{"click":function($event){$event.preventDefault();return _vm.$inertia.post(link.href, { property_id: _vm.id })}}},[_vm._v(_vm._s(link.label))])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }