<template>
    <elly-form @submit="$inertia.post(meta.links.store, values)" :categories="categories" :marketingkinds="marketingkinds" :countries="countries" :regions="regions" v-model="values"></elly-form>
</template>

<script>
import EllyForm from './EllyForm';
import App from '../../layouts/App';

export default {
    layout: App,

    props: {
        meta: {},
        categories: {},
        marketingkinds: {},
        countries: {},
        regions: {},
    },

    data: function() {
        return {
            values: {
                images: [],
                'property_category_id': 1,
                'property_kind_id': 2,
                'property_usage_id': 2,
                'marketing_kind_id': 5,
                'objekt_id': 'H2016JS25S',
                'wohnflaeche': '12200',
                'nutzflaeche': '10000',
                'kellerflaeche': '11',
                'stellplatzart': 'kein',
                'anzzimmer': 4,
                'anzschlafzimmer': 2,
                'anzbadezimmer': 2,
                'anzgaestewc': 2,
                'anzbalkone': 2,
                'price': 66000000,
                'address': 'Itterstr',
                'address_nr': '3',
                'bundesland': 'NRW',
                'land': 'Deutschland',
                'verfuegbar_ab': '13.03.2022',
                'show_address': [],
                'bewohnt': true,
                'vermietet': true,
                'zip': '42719',
                'location': 'Solingen',
                'anzterrasse': 1,
                'title': 'Neue Immobikie',
                'amtsgericht': '2233e',
                'bezirk': 'BL',
                'grundbuchblatt': 'AAA',
                'gemarkung': 'ERRRR',
                'flur': 'wew',
                'flurstueck': '122',
                'dienstbarkeiten': 'AA',
                'flgrundstueck': '3333',
                'flgarten': '22333',
                'flwald': 'TTT',
                'flsonstige': 'AAA',
                'flland': '11',
                'altlasten': '12121',
                'baulasten': '1221212',
                'grunddienstbarkeiten': 'ttt',
                'bodenrichtwert': 'errr'

            }
        };
    },

    components: { EllyForm }
};
</script>

