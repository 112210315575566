<template>
    <form method="post" @submit.prevent="$inertia.patch('/profile', data)">
        <grid>
            <item>
                <tabs v-model="tab">
                    <tab label="Persönliche Daten">
                        <grid columns="1fr 1fr" fullsize>
                            <item><f-text label="Vorname" v-model="data.firstname"></f-text></item>
                            <item><f-text label="Nachname" v-model="data.lastname"></f-text></item>
                            <item><f-text label="Adresse" v-model="data.address"></f-text></item>
                            <item><f-text label="PLZ" v-model="data.zip"></f-text></item>
                            <item><f-text label="Ort" v-model="data.location"></f-text></item>
                            <item><f-text label="Telefonnummer" v-model="data.phone"></f-text></item>
                            <item><f-text label="Email" v-model="data.email"></f-text></item>
                        </grid>
                    </tab>
                    <tab label="Accounts">
                        <imap-account v-model="data.imapAccount">></imap-account>
                    </tab>
                    <tab label="Eigene Felder">
                        <grid>
                            <item><f-text v-model="data.bcc_email" label="BCC-E-Mail"></f-text></item>
                            <item><f-textarea v-model="data.widerruf" label="Widerrufsbelehrung"></f-textarea></item>
                            <item><f-textarea v-model="data.impressum" label="Impressum"></f-textarea></item>
                            <item><f-textarea v-model="data.signatur" label="Signatur"></f-textarea></item>
                            <item><f-textarea v-model="data.agb" label="AGB"></f-textarea></item>
                            <item><f-textarea v-model="data.datenschutz" label="Datenschutz"></f-textarea></item>
                        </grid>
                    </tab>

                    <!-- 
                    <tab label="Documents">
                        <grid fullsize>
                            <item>
                                <f-checkbox label="Dokumente nutzen" v-model="hasowncloud"></f-checkbox>
                            </item>

                            <item v-if="hasowncloud"><f-text v-model="data.owncloud_account.username" label="Benutzer"></f-text></item>
                            <item v-if="hasowncloud"><f-password v-model="data.owncloud_account.password" label="Passwort"></f-password></item>
                        </grid>
                    </tab>
                    -->

                </tabs>
            </item>

            <item>
                <f-submit primary>Absenden</f-submit>
            </item>
        </grid>
    </form>
</template>

<script>
import App from '../../layouts/App';
import ImapAccount from './ImapAccount.vue';

export default {

    layout: App,

    data: function() {
        return {
            tab: 'persönliche-daten',
        };
    },

    props: {
        data: {}
    },

    computed: {
        hasowncloud: {
            set(v) {
                this.values.owncloud_account = v ? {} : null;
            },
            get() {
                return this.values.owncloud_account !== null;
            }
        }
    },

    components: { ImapAccount }
};
</script>
