<template>
    <div class="relative p-6">
        <toolbar>
            <goto :href="data.meta.links.create" icon="plus" class="text-white">Adresse anlegen</goto>
        </toolbar>

        <v-table :headers="[
            {text: 'Name', value: 'fullname', align: 'left'},
            {text: 'E-Mail', value: 'email', align: 'left'},
            {text: 'Adresse', value: 'address', align: 'left'},
            {text: 'Kategorie', value: 'category_id', align: 'left'},
            ]"
            :items="data.data"
            actions
        >
            <template slot="Name" slot-scope="{ item }">
                {{item.firstname}} {{item.lastname}}
            </template>

            <template slot="E-Mail" slot-scope="{ item }">
                {{item.email}}
            </template>

            <template slot="Adresse" slot-scope="{ item }">
                {{item.address}} {{item.nr}}, {{item.zip}} {{item.location}}
            </template>

            <template slot="Kategorie" slot-scope="{ item }">
                {{item.category.title}}
            </template>

            <template slot="actions" slot-scope="{ item }">
                <goto :href="item.links.edit" icon="pencil" class="btn btn-primary ml-1 text-white"></goto>
                <goto  @click="$inertia.delete(item.links.destroy)" icon="trash" class="btn btn-danger ml-1 text-white"></goto>
            </template>
        </v-table>
    </div>
</template>

<script>
import App from '../../layouts/App';

export default {
    
    layout: App,

    data: function() {
        return {
            values: {}
        };
    },

    props: {
        data: {},
        categories: {}
    }

};
</script>
