var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-6"},[_c('div',{staticClass:"flex items-center"},[_c('label',{staticClass:"text-xs text-gray-800 mr-2",attrs:{"for":"search"}},[_vm._v("Suchen")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],staticClass:"p-2 text-sm bg-gray-200",attrs:{"type":"text","name":"search"},domProps:{"value":(_vm.searchValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchValue=$event.target.value}}})])]),_vm._v(" "),_c('v-table',{attrs:{"headers":[
        {text: 'Vorname', value: 'firstname', align: 'left'},
        {text: 'Nachname', value: 'lastname', align: 'left'},
        {text: 'Addresse', value: 'address', align: 'left'},
        {text: 'PLZ', value: 'zip', align: 'left'},
        {text: 'Ort', value: 'location', align: 'left'}
        ],"items":_vm.data.data,"sort":_vm.sortValue,"actions":""},on:{"sort":function($event){_vm.sortValue = $event}},scopedSlots:_vm._u([{key:"Vorname",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.firstname)+"\n        ")]}},{key:"Nachname",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.lastname)+"\n        ")]}},{key:"Addresse",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.address)+"\n        ")]}},{key:"PLZ",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.zip)+"\n        ")]}},{key:"Ort",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.location)+"\n        ")]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [_c('goto',{staticClass:"btn btn-primary ml-1 text-white",attrs:{"href":item.links.edit,"icon":"pencil"}}),_vm._v(" "),_c('goto',{staticClass:"btn btn-primary ml-1 text-white",attrs:{"href":item.links.delete,"icon":"calendar"}})]}}])}),_vm._v(" "),_c('pagination',{attrs:{"value":_vm.data.meta},on:{"goto":function($event){_vm.pageValue = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }