<template>
    <div id="app" class="font-sans flex flex-col flex-grow">
        <notification :errors="$page.errors"></notification>
        <progress></progress>
        <wrapper
            admin-toolbar="adminToolbar"
            profile-toolbar="profileToolbar"
        >
            <template slot="mainnav">
                <mainnav name="main" sm></mainnav>
            </template>

            <div slot="brand-icon">
                <inertia-link href="/">
                    <img src="/img/next-immobilien-weiss.png">
                </inertia-link>
            </div>

            <template slot="main">
                <slot />
            </template>
        </wrapper>
    </div>
</template>

<script>
import Notification from 'agnoster/components/Notification.vue';
import Progress from 'agnoster/components/Progress.vue';
import Wrapper from 'agnoster/components/Wrapper.vue';
import Mainnav from 'agnoster/components/Mainnav.vue';

export default {
    computed: {
        title() {
            return this.$store.getters.title;
        }
    },
    components: { Notification, Wrapper, Mainnav, Progress },
    created() {
        this.$store.commit('user/set', this.$page.auth.user);
    }
};
</script>
