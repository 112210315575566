<template>
    <div class="tab-container" :class="{'h-full flex flex-col': stretch === true}">
        <div class="tabs flex justify-center border-b-2 mb-4 border-grey-light">
            <a href="#" @click.prevent="change(tab)" v-for="tab in tabs"
            class="p-2 no-underline text-center text-md text-grey cursor-pointer tab-item"
            :class="{'border-b-2 border-solid border-primary': value === tab.labelId}"
            :key="tab.labelId"
            >
                {{ tab.label }}
            </a>
        </div>

        <div class="tab-contents overflow-hidden" :class="{'flex-grow': stretch}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            tabs: []
        };
    },
    props: {
        value: {},
        stretch: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value(v) {
            this.$children.forEach((child) => {
                child.$emit('setVisibility', child.labelId === v);
            });
        }
    },
    methods: {
        change(tab) {
            this.$emit('input', tab.labelId);
        }
    },
    mounted() {
        this.tabs = this.$children;

        this.$children.forEach((child) => {
            child.$emit('setVisibility', child.labelId === this.value);
        });
    }
};
</script>

<style scoped>
    .tab-item {
        position: relative;
        top: 2px;
    }
</style>
