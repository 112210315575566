<template>
    <grid columns="1fr 1fr" v-if="typeof this.value !== 'undefined'">
        <item :key="key" v-for="(heading, key) in Object.keys(this.defaults)" :col="key % 2 + 1">
            <div class="subtitle" v-html="heading"></div>
            <f-text v-model="value[heading].year" label="Sanierungsjahr"></f-text>
            <f-text v-model="value[heading].umfang" label="Umfang (%)"></f-text>
            <f-text v-model="value[heading].description" label="Was wurde gemacht"></f-text>
        </item>
    </grid>
</template>

<script>
export default {
    data: function() {
        return {
            defaults: {
                'Dach': { year: '', umfang: 0, description: '' },
                'Mauerwerk': { year: '', umfang: 0, description: '' },
                'Wasserleitungen': { year: '', umfang: 0, description: '' },
                'Stromleitungen': { year: '', umfang: 0, description: '' },
                'Heizungsanlage': { year: '', umfang: 0, description: '' },
                'Fenster': { year: '', umfang: 0, description: '' },
                'Dämmung': { year: '', umfang: 0, description: '' },
                'Isolierung': { year: '', umfang: 0, description: '' }
            }
        };
    },
    props: {
        value: {}
    },
    created() {
        if (typeof this.value === 'undefined') {
            this.$emit('input', this.defaults);
        }
    }
};
</script>
