<template>
    <div>
        <span class="label-font label-placeholder label-placeholder-focused" v-if="label">{{ label }}</span>

        <f-switch :key="option" v-for="(label, option) in options" :items="isSet(option)" @input="setValue(option, $event)" :label="label" sm></f-switch>
    </div>
</template>

<script>
import FormField from './FormField.js';

export default {
    extends: FormField,
    props: {
        value: {
            default: function() {
                return {};
            }
        },
        label: {
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        options: {
            default: function() { return []; }
        }
    },
    methods: {
        isSet(option) {
            return this.value.indexOf(option) !== -1;
        },
        setValue(option, value) {
            var v = this.value;
            if (this.value.indexOf(option) === -1 && value === true) {
                v.push(option);
            }

            if (this.value.indexOf(option) !== -1 && value === false) {
                v = v.filter(key => key !== option);
            }

            this.$emit('input', v);
        }
    },
    mounted() {
        console.log(this.value);
    }
};
</script>
