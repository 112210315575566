<template>
    <div class="mr-3 mt-3 top-0 right-0 fixed z-20">
        <transition-group name="fadeRight">
            <div v-for="(item, index) in items" :key="'i'+index"
                :class="['bg-'+item.type]"
                class="flex items-center py-4 px-6 mt-2 shadow-full rounded-sm"
            >
                <div class="text-white text-xs notification" v-html="item.message"></div>
            </div>
        </transition-group>
    </div>
</template>

<script>
export default {

    props: {
        errors: {}
    },

    computed: {
        items() {
            var i = [];
            
            for (const field in this.errors) {
                this.errors[field].forEach(error => {
                    i.push({ message: error, type: 'danger' });
                });
            }

            return i;
        }
    }

};
</script>
