<template>
    <div @click.self="closeAll" class="fixed top-0 pin-l h-screen w-screen z-30 flex items-center justify-center bg-black-transparent" v-if="any">
        <div class="bg-white shadow rounded-sm p-6 w-4/5 overflow-auto max-h-4/5 max-w-lg" v-for="(item, index) in items" :key="index">
            <v-component :is="item.component" v-bind="item.params"></v-component>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        items() {
            return this.$store.getters['modal/all'];
        },
        any() {
            return this.$store.getters['modal/any'];
        }
    },
    methods: {
        closeAll() {
            this.$store.dispatch('modal/closeAll');
        }
    }
};
</script>

<style scoped>
    .bg-black-transparent {
        background: rgba(0, 0, 0, 0.3);
    }
</style>
