<template>
    <div class="mr-3 mb-3 bottom-0 right-0 fixed z-20">
        <transition-group name="fadeRight">
            <div v-for="item, index in items" :key="item.identifier"
                :class="['bg-'+item.type]"
                class="flex relative flex-col py-4 px-6 mt-2 shadow-full rounded-sm"
            >
                <a href="#" class="right-0 top-0 text-darker-5 absolute mr-2 mt-2" @click.prevent="close(index)">
                    <svg class="w-3 h-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/></svg>
                </a>
                <div class="text-white mb-1 font-semibold text-xs notification" v-html="item.name"></div>
                <div class="text-white text-xs notification" v-html="parseMessage(item.message)"></div>
                <div v-for="(action, index) in item.actions" class="mt-2" :key="index">
                    <component :is="action.type" v-bind="action"></component>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            items: []
        };
    },
    methods: {
        parseMessage(message) {
            if (typeof message === 'undefined') { return ''; }
            return message.join('<br>');
        },
        close(index) {
            this.items.splice(index, 1);
        }
    },
    mounted() {
        Echo.channel('progress')
            .listen('.Aweos\\Agnoster\\Progress\\ProgressStartedEvent', (ret) => {
                this.items.push({
                    type: 'info',
                    message: [],
                    identifier: ret.identifier,
                    name: ret.name
                });
            })
            .listen('.Aweos\\Agnoster\\Progress\\ProgressAdvancedEvent', (ret) => {
                var p = this.items.find(p => p.identifier === ret.identifier);
                if (p === undefined) { return; }
                p.message = ret.message;
            })
            .listen('.Aweos\\Agnoster\\Progress\\ProgressFinishedEvent', (ret) => {
                var p = this.items.find(p => p.identifier === ret.identifier);
                if (p === undefined) { return; }
                p.message = ret.message;
                p.type = 'success';
            })
            .listen('.Aweos\\Agnoster\\Progress\\ProgressFailedEvent', (ret) => {
                var p = this.items.find(p => p.identifier === ret.identifier);
                if (p === undefined) { return; }
                p.message = ret.message;
                p.type = 'danger';
            })
        ;
    }
};
</script>
